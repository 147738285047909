// src/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-white dark:bg-gray-800 text-black dark:text-white py-6 border-t border-gray-200 dark:border-gray-700">
      <div className="container mx-auto px-4 max-w-7xl flex flex-col md:flex-row justify-between items-center">
        {/* Copyright */}
        <p className="text-sm text-center md:text-left mb-4 md:mb-0">
          &copy; {new Date().getFullYear()} SubwaySurveys
        </p>

        {/* Navigation Links */}
        <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-6">
          <Link to="/terms-of-service" className="hover-underline text-sm">
            Podmínky používání
          </Link>
          <Link to="/privacy-policy" className="hover-underline text-sm">
            Zásady ochrany osobních údajů
          </Link>
          <Link to="/cookie-policy" className="hover-underline text-sm">
            Zásady používání cookies
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
