// src/TermsOfService.js

import React from 'react';

function TermsOfService() {
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 px-4 py-8">
      <div className="max-w-[800px] mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 mb-8 border-t-4 border-accentYellow">
        <h1 className="text-3xl font-bold text-black dark:text-white mb-6">
          Podmínky používání
        </h1>
        <div className="text-black dark:text-white">
          <p className="mb-4 text-sm text-gray-600 dark:text-gray-400">
            Naposledy aktualizováno 29. listopadu 2024
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            SOUHLAS S NAŠIMI PRÁVNÍMI PODMÍNKAMI
          </h2>

          <p className="mb-4">
            Jsme SubwaySurveys ("Společnost", "my", "nás", "naše").
          </p>

          <p className="mb-4">
            Provozujeme webovou stránku <strong>subwaysurveys.eu</strong> ("Stránka") a také
            jakékoli další související produkty a služby, které odkazují nebo se vztahují k
            těmto právním podmínkám (souhrnně "Služby").
          </p>

          <p className="mb-4">
            Poskytujeme platformu, která umožňuje uživatelům vytvářet, sdílet a spravovat
            ankety. Naše služba umožňuje uživatelům navrhovat ankety, sbírat zpětnou vazbu a
            analyzovat výsledky v reálném čase. Platforma je navržena tak, aby zajistila
            snadné použití, bezpečnost a přístupnost z jakéhokoli zařízení.
          </p>

          <p className="mb-4">
            Můžete nás kontaktovat e-mailem na{' '}
            <a href="mailto:urbanekd.05@spst.eu" className="text-accentYellow">
              urbanekd.05@spst.eu
            </a>{' '}
          </p>

          <p className="mb-4">
            Tyto Právní podmínky představují právně závaznou dohodu uzavřenou mezi vámi,
            ať už osobně nebo jménem entity ("vy"), a SubwaySurveys, týkající se vašeho
            přístupu k Službám a jejich používání. Souhlasíte s tím, že přístupem ke Službám
            jste si přečetli, porozuměli a souhlasíte s tím, že budete vázáni všemi těmito
            Právními podmínkami. <strong>
              POKUD NESOUHLASÍTE SE VŠEMI TĚMITO PRÁVNÍMI PODMÍNKAMI, JE VÁM VÝSLOVNĚ
              ZAKÁZÁNO POUŽÍVAT SLUŽBY A MUSÍTE OKAMŽITĚ UKONČIT JEJICH POUŽÍVÁNÍ.
            </strong>
          </p>

          <p className="mb-4">
            Doplňkové podmínky nebo dokumenty, které mohou být čas od času zveřejněny na
            Službách, jsou tímto výslovně začleněny odkazem. Vyhrazujeme si právo podle
            vlastního uvážení provádět změny nebo úpravy těchto Právních podmínek kdykoli
            a z jakéhokoli důvodu. Upozorníme vás na jakékoli změny aktualizací data
            "Naposledy aktualizováno" těchto Právních podmínek a vzdáváte se jakéhokoli
            práva obdržet zvláštní oznámení o každé takové změně. Je vaší odpovědností
            pravidelně kontrolovat tyto Právní podmínky, abyste byli informováni o
            aktualizacích. Budete podléhat a budete považováni za informované a za to, že
            jste přijali změny v jakýchkoli revidovaných Právních podmínkách tím, že budete
            nadále používat Služby po datu zveřejnění takových revidovaných Právních
            podmínek.
          </p>

          <p className="mb-4">
            Všichni uživatelé, kteří jsou nezletilí v jurisdikci, ve které bydlí (obecně
            mladší 18 let), musí mít povolení a být přímo pod dohledem svého rodiče nebo
            opatrovníka, aby mohli používat Služby. Pokud jste nezletilí, musíte požádat
            svého rodiče nebo opatrovníka, aby si přečetl a souhlasil s těmito Právními
            podmínkami před tím, než budete používat Služby.
          </p>

          <p className="mb-4">
            Doporučujeme, abyste si vytiskli kopii těchto Právních podmínek pro své
            záznamy.
          </p>

          {/* OBSAH */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">OBSAH</h2>
          <ol className="list-decimal list-inside mb-4 space-y-2">
            <li>
              <a href="#section1" className="text-accentYellow">
                NAŠE SLUŽBY
              </a>
            </li>
            <li>
              <a href="#section2" className="text-accentYellow">
                PRÁVA DUŠEVNÍHO VLASTNICTVÍ
              </a>
            </li>
            <li>
              <a href="#section3" className="text-accentYellow">
                PROHLÁŠENÍ UŽIVATELE
              </a>
            </li>
            <li>
              <a href="#section4" className="text-accentYellow">
                REGISTRACE UŽIVATELE
              </a>
            </li>
            <li>
              <a href="#section5" className="text-accentYellow">
                ZAKÁZANÉ AKTIVITY
              </a>
            </li>
            <li>
              <a href="#section6" className="text-accentYellow">
                PŘÍSPĚVKY VYTVOŘENÉ UŽIVATELI
              </a>
            </li>
            <li>
              <a href="#section7" className="text-accentYellow">
                LICENCE K PŘÍSPĚVKŮM
              </a>
            </li>
            <li>
              <a href="#section8" className="text-accentYellow">
                SOCIÁLNÍ MÉDIA
              </a>
            </li>
            <li>
              <a href="#section9" className="text-accentYellow">
                SPRÁVA SLUŽEB
              </a>
            </li>
            <li>
              <a href="#section10" className="text-accentYellow">
                ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ
              </a>
            </li>
            <li>
              <a href="#section11" className="text-accentYellow">
                PORUŠENÍ AUTORSKÝCH PRÁV
              </a>
            </li>
            <li>
              <a href="#section12" className="text-accentYellow">
                DOBA TRVÁNÍ A UKONČENÍ
              </a>
            </li>
            <li>
              <a href="#section13" className="text-accentYellow">
                ÚPRAVY A PŘERUŠENÍ
              </a>
            </li>
            <li>
              <a href="#section14" className="text-accentYellow">
                ROZHODNÉ PRÁVO
              </a>
            </li>
            <li>
              <a href="#section15" className="text-accentYellow">
                ŘEŠENÍ SPORŮ
              </a>
            </li>
            <li>
              <a href="#section16" className="text-accentYellow">
                OPRAVY
              </a>
            </li>
            <li>
              <a href="#section17" className="text-accentYellow">
                PROHLÁŠENÍ
              </a>
            </li>
            <li>
              <a href="#section18" className="text-accentYellow">
                OMEZENÍ ODPOVĚDNOSTI
              </a>
            </li>
            <li>
              <a href="#section19" className="text-accentYellow">
                ODŠKODNĚNÍ
              </a>
            </li>
            <li>
              <a href="#section20" className="text-accentYellow">
                UŽIVATELSKÁ DATA
              </a>
            </li>
            <li>
              <a href="#section21" className="text-accentYellow">
                ELEKTRONICKÁ KOMUNIKACE, TRANSAKCE A PODPISY
              </a>
            </li>
            <li>
              <a href="#section22" className="text-accentYellow">
                RŮZNÉ
              </a>
            </li>
            <li>
              <a href="#section23" className="text-accentYellow">
                KONTAKTUJTE NÁS
              </a>
            </li>
          </ol>

          {/* 1. NAŠE SLUŽBY */}
          <h2 id="section1" className="text-2xl font-semibold mt-6 mb-4">
            1. NAŠE SLUŽBY
          </h2>
          <p className="mb-4">
            Informace poskytované při používání Služeb nejsou určeny k distribuci nebo
            použití jakoukoli osobou nebo entitou v jakékoli jurisdikci nebo zemi, kde by
            taková distribuce nebo použití bylo v rozporu se zákonem nebo předpisem nebo
            by nás podléhalo jakémukoli registračnímu požadavku v takové jurisdikci nebo
            zemi. Ti, kteří se rozhodnou přistupovat ke Službám z jiných míst, tak činí z
            vlastní iniciativy a jsou výhradně odpovědní za dodržování místních zákonů, pokud
            a v rozsahu, v jakém jsou místní zákony použitelné.
          </p>

          {/* 2. PRÁVA DUŠEVNÍHO VLASTNICTVÍ */}
          <h2 id="section2" className="text-2xl font-semibold mt-6 mb-4">
            2. PRÁVA DUŠEVNÍHO VLASTNICTVÍ
          </h2>
          <h3 className="text-xl font-semibold mt-4 mb-2">Naše duševní vlastnictví</h3>
          <p className="mb-4">
            Jsme vlastníkem nebo držitelem licence všech práv duševního vlastnictví k našim
            Službám, včetně veškerého zdrojového kódu, databází, funkčnosti, softwaru,
            návrhů webových stránek, zvuku, videa, textu, fotografií a grafiky v rámci
            Služeb (souhrnně "Obsah"), stejně jako ochranných známek, servisních značek a
            log obsažených v nich ("Značky").
          </p>
          <p className="mb-4">
            Náš Obsah a Značky jsou chráněny autorským právem a zákony o ochranných
            známkách (a různými dalšími právy duševního vlastnictví a zákony o nekalé
            soutěži) a mezinárodními smlouvami po celém světě.
          </p>
          <p className="mb-4">
            Obsah a Značky jsou poskytovány v rámci nebo prostřednictvím Služeb "TAK, JAK
            JSOU" pouze pro vaše osobní, nekomerční použití nebo pro interní obchodní
            účely.
          </p>
          <h3 className="text-xl font-semibold mt-4 mb-2">Vaše použití našich Služeb</h3>
          <p className="mb-4">
            S výhradou vašeho dodržování těchto Právních podmínek, včetně sekce{' '}
            <a href="#section5" className="text-accentYellow">
              "ZAKÁZANÉ AKTIVITY"
            </a>{' '}
            níže, vám udělujeme nevýhradní, nepřevoditelnou, odvolatelnou licenci k:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>přístupu ke Službám</li>
            <li>
              stažení nebo vytištění kopie jakékoli části Obsahu, ke kterému jste získali
              řádný přístup,
            </li>
          </ul>
          <p className="mb-4">
            výhradně pro vaše osobní, nekomerční použití nebo pro interní obchodní účely.
          </p>
          <p className="mb-4">
            S výjimkou případů uvedených v této sekci nebo jinde v našich Právních
            podmínkách nesmí být žádná část Služeb a žádný Obsah nebo Značky kopírovány,
            reprodukovány, agregovány, znovu publikovány, nahrávány, zveřejňovány,
            veřejně zobrazovány, kódovány, překládány, přenášeny, distribuovány,
            prodávány, licencovány nebo jinak využívány pro jakýkoli komerční účel bez
            našeho výslovného předchozího písemného souhlasu.
          </p>
          <p className="mb-4">
            Pokud si přejete jakýmkoli způsobem použít Služby, Obsah nebo Značky jinak,
            než je uvedeno v této sekci nebo jinde v našich Právních podmínkách, zašlete
            svou žádost na:{' '}
            <a href="mailto:urbanekd.05@spst.eu" className="text-accentYellow">
              urbanekd.05@spst.eu
            </a>
            . Pokud vám někdy udělíme povolení k
            zveřejnění, reprodukci nebo veřejnému zobrazení jakékoli části našich Služeb
            nebo Obsahu, musíte nás identifikovat jako vlastníky nebo držitele licence
            Služeb, Obsahu nebo Značek a zajistit, aby se při zveřejnění, reprodukci nebo
            zobrazení našeho Obsahu objevilo nebo bylo viditelné jakékoli oznámení o
            autorských právech nebo vlastnictví.
          </p>
          <p className="mb-4">
            Vyhrazujeme si všechna práva, která vám nejsou výslovně udělena v souvislosti
            se Službami, Obsahem a Značkami.
          </p>
          <p className="mb-4">
            Jakékoli porušení těchto práv duševního vlastnictví bude představovat
            podstatné porušení našich Právních podmínek a vaše právo používat naše Služby
            bude okamžitě ukončeno.
          </p>

          {/* 3. PROHLÁŠENÍ UŽIVATELE */}
          <h2 id="section3" className="text-2xl font-semibold mt-6 mb-4">
            3. PROHLÁŠENÍ UŽIVATELE
          </h2>
          <p className="mb-4">
            Používáním Služeb prohlašujete a zaručujete, že: (1) všechny registrační
            informace, které poskytnete, budou pravdivé, přesné, aktuální a úplné; (2)
            budete udržovat přesnost těchto informací a okamžitě aktualizujete tyto
            registrační informace podle potřeby; (3) máte právní způsobilost a souhlasíte
            s tím, že budete dodržovat tyto Právní podmínky; (4) nejste nezletilí v
            jurisdikci, ve které bydlíte, nebo pokud jste nezletilí, obdrželi jste
            rodičovské povolení k používání Služeb; (5) nebudete přistupovat ke Službám
            prostřednictvím automatizovaných nebo ne-lidských prostředků, ať už prostřednictvím
            bota, skriptu nebo jinak; (6) nebudete používat Služby pro jakýkoli nelegální
            nebo neoprávněný účel; a (7) vaše použití Služeb nebude porušovat žádný
            platný zákon nebo předpis.
          </p>
          <p className="mb-4">
            Pokud poskytnete jakékoli informace, které jsou nepravdivé, nepřesné,
            neaktuální nebo neúplné, máme právo pozastavit nebo ukončit váš účet a odmítnout
            jakékoli a veškeré současné nebo budoucí používání Služeb (nebo jakékoli jejich
            části).
          </p>

          {/* 4. REGISTRACE UŽIVATELE */}
          <h2 id="section4" className="text-2xl font-semibold mt-6 mb-4">
            4. REGISTRACE UŽIVATELE
          </h2>
          <p className="mb-4">
            Můžete být požádáni o registraci pro používání Služeb. Souhlasíte s tím, že
            budete udržovat své heslo v tajnosti a budete zodpovědní za veškeré použití
            svého účtu a hesla. Vyhrazujeme si právo odstranit, nárokovat nebo změnit
            uživatelské jméno, které si vyberete, pokud podle našeho výhradního uvážení
            zjistíme, že takové uživatelské jméno je nevhodné, obscénní nebo jinak
            závadné.
          </p>

          {/* 5. ZAKÁZANÉ AKTIVITY */}
          <h2 id="section5" className="text-2xl font-semibold mt-6 mb-4">
            5. ZAKÁZANÉ AKTIVITY
          </h2>
          <p className="mb-4">
            Nesmíte přistupovat ke Službám nebo je používat pro jakýkoli jiný účel, než
            pro který zpřístupňujeme Služby. Služby nesmí být používány v souvislosti s
            jakýmikoli komerčními aktivitami s výjimkou těch, které jsou námi výslovně
            schváleny nebo schváleny.
          </p>
          <p className="mb-4">Jako uživatel Služeb souhlasíte s tím, že nebudete:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>
              Systematicky získávat data nebo jiný obsah ze Služeb k vytvoření nebo
              kompilaci, přímo či nepřímo, sbírky, kompilace, databáze nebo adresáře bez
              našeho písemného povolení.
            </li>
            <li>
              Oklamat, podvést nebo uvést v omyl nás a další uživatele, zejména v jakémkoli
              pokusu získat citlivé informace o účtu, jako jsou uživatelská hesla.
            </li>
            <li>
              Obcházet, deaktivovat nebo jinak zasahovat do bezpečnostních funkcí Služeb,
              včetně funkcí, které brání nebo omezují použití nebo kopírování jakéhokoli
              Obsahu nebo vynucují omezení používání Služeb a/nebo Obsahu v nich
              obsaženého.
            </li>
            <li>
              Hanobit, poškozovat nebo jinak škodit, podle našeho názoru, nám a/nebo
              Službám.
            </li>
            <li>
              Používat jakékoli informace získané ze Služeb za účelem obtěžování,
              zneužívání nebo poškozování jiné osoby.
            </li>
            <li>
              Zneužívat naše podpůrné služby nebo podávat nepravdivé zprávy o zneužití
              nebo pochybení.
            </li>
            <li>
              Používat Služby způsobem neslučitelným s jakýmikoli platnými zákony nebo předpisy.
            </li>
            <li>Zapojit se do neoprávněného rámování nebo odkazování na Služby.</li>
            <li>
              Nahrávat nebo přenášet (nebo se pokoušet nahrát nebo přenést) viry, trojské
              koně nebo jiný materiál, který interferuje s nepřerušovaným používáním a
              užíváním Služeb jakékoli strany nebo modifikuje, zhoršuje, narušuje,
              mění nebo zasahuje do používání, funkcí, provozu nebo údržby Služeb.
            </li>
            <li>
              Zapojit se do jakéhokoli automatizovaného používání systému, jako je
              používání skriptů k odesílání komentářů nebo zpráv, nebo používání
              jakýchkoli dolovacích nástrojů, robotů nebo podobných nástrojů pro
              shromažďování a extrakci dat.
            </li>
            <li>
              Odstraňovat oznámení o autorských právech nebo jiných vlastnických právech z
              jakéhokoli Obsahu.
            </li>
            <li>
              Pokoušet se vydávat za jiného uživatele nebo osobu nebo používat uživatelské
              jméno jiného uživatele.
            </li>
            <li>
              Nahrávat nebo přenášet (nebo se pokoušet nahrát nebo přenést) jakýkoli
              materiál, který funguje jako pasivní nebo aktivní mechanismus pro sběr nebo
              přenos informací, včetně bez omezení, jasných grafických formátů pro
              výměnu ("gify"), 1×1 pixelů, webových chyb, cookies nebo jiných podobných
              zařízení (někdy označovaných jako "spyware" nebo "pasivní sběrné
              mechanismy" nebo "pcms").
            </li>
            <li>
              Zasahovat do, narušovat nebo vytvářet nepřiměřenou zátěž pro Služby nebo
              sítě či služby připojené ke Službám.
            </li>
            <li>
              Obtěžovat, obtěžovat, zastrašovat nebo vyhrožovat jakýmkoli našim
              zaměstnancům nebo agentům zapojeným do poskytování jakékoli části Služeb
              vám.
            </li>
            <li>
              Pokoušet se obejít jakákoliv opatření Služeb určená k zabránění nebo
              omezení přístupu ke Službám nebo jakékoli části Služeb.
            </li>
            <li>
              Kopírovat nebo přizpůsobovat software Služeb, včetně, ale bez omezení na
              Flash, PHP, HTML, JavaScript nebo jiný kód.
            </li>
            <li>
              S výjimkou případů povolených platným zákonem rozkládat, dekompilovat,
              demontovat nebo zpětně analyzovat jakýkoli software tvořící nebo jakýmkoli
              způsobem tvořící součást Služeb.
            </li>
            <li>
              S výjimkou standardního použití vyhledávače nebo internetového prohlížeče
              používat, spouštět, vyvíjet nebo distribuovat jakýkoli automatizovaný
              systém, včetně bez omezení jakéhokoli pavouka, robota, cheat utility,
              scraperu nebo offline čtečky, která přistupuje ke Službám, nebo používat či
              spouštět jakýkoli neoprávněný skript nebo jiný software.
            </li>
            <li>
              Používat agenta pro nákup nebo nákupního agenta k provádění nákupů na
              Službách.
            </li>
            <li>
              Provádět jakékoli neoprávněné použití Služeb, včetně shromažďování
              uživatelských jmen a/nebo e-mailových adres uživatelů elektronickými nebo
              jinými prostředky za účelem zasílání nevyžádaných e-mailů nebo vytváření
              uživatelských účtů automatizovanými prostředky nebo pod falešnými
              záminkami.
            </li>
            <li>
              Používat Služby jako součást jakéhokoli úsilí konkurovat nám nebo jinak
              používat Služby a/nebo Obsah pro jakékoli výdělečné úsilí nebo komerční
              podnik.
            </li>
          </ul>

          {/* 6. PŘÍSPĚVKY VYTVOŘENÉ UŽIVATELI */}
          <h2 id="section6" className="text-2xl font-semibold mt-6 mb-4">
            6. PŘÍSPĚVKY VYTVOŘENÉ UŽIVATELI
          </h2>
          <p className="mb-4">
            Služby mohou vyzvat k chatování, přispívání nebo účasti na blozích, nástěnkách,
            online fórech a jiných funkcionalitách a mohou vám poskytnout příležitost
            vytvářet, odesílat, zveřejňovat, zobrazovat, přenášet, provádět, publikovat,
            distribuovat nebo vysílat obsah a materiály nám nebo na Službách, včetně, ale
            bez omezení na text, psaní, video, zvuk, fotografie, grafiku, komentáře,
            návrhy nebo osobní informace nebo jiný materiál (souhrnně "Příspěvky").
            Příspěvky mohou být viditelné pro ostatní uživatele Služeb a prostřednictvím
            webových stránek třetích stran. Jako takové mohou být jakékoli Příspěvky, které
            přenesete, považovány za ne-důvěrné a ne-vlastnické. Když vytváříte nebo
            zpřístupňujete jakékoli Příspěvky, prohlašujete a zaručujete, že:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>
              Vytvoření, distribuce, přenos, veřejné zobrazení nebo výkon a přístup,
              stahování nebo kopírování vašich Příspěvků neporušují a nebudou porušovat
              vlastnická práva, včetně, ale bez omezení na autorská práva, patenty,
              ochranné známky, obchodní tajemství nebo morální práva jakékoli třetí strany.
            </li>
            <li>
              Jste tvůrcem a vlastníkem nebo máte nezbytné licence, práva, souhlasy,
              uvolnění a povolení k použití a k autorizaci nás, Služeb a dalších uživatelů
              Služeb k použití vašich Příspěvků jakýmkoli způsobem zamýšleným Službami a
              těmito Právními podmínkami.
            </li>
            <li>
              Máte písemný souhlas, uvolnění a/nebo povolení každé identifikovatelné
              jednotlivé osoby ve vašich Příspěvcích použít jméno nebo podobu každé takové
              identifikovatelné jednotlivé osoby, aby bylo možné zahrnout a použít vaše
              Příspěvky jakýmkoli způsobem zamýšleným Službami a těmito Právními
              podmínkami.
            </li>
            <li>Vaše Příspěvky nejsou nepravdivé, nepřesné nebo zavádějící.</li>
            <li>
              Vaše Příspěvky nejsou nevyžádaná nebo neoprávněná reklama, propagační
              materiály, pyramidové schémata, řetězové dopisy, spam, hromadné rozesílání
              nebo jiné formy žádostí.
            </li>
            <li>
              Vaše Příspěvky nejsou obscénní, oplzlé, lascivní, špinavé, násilné,
              obtěžující, pomlouvačné, urážlivé nebo jinak závadné (podle našeho uvážení).
            </li>
            <li>
              Vaše Příspěvky neslouží k zesměšňování, zesměšňování, hanobení, zastrašování
              nebo zneužívání kohokoli.
            </li>
            <li>
              Vaše Příspěvky nejsou používány k obtěžování nebo vyhrožování (v právním
              smyslu těchto termínů) jakékoli jiné osoby a k propagaci násilí proti
              konkrétní osobě nebo třídě osob.
            </li>
            <li>
              Vaše Příspěvky neporušují žádný platný zákon, předpis nebo pravidlo.
            </li>
            <li>
              Vaše Příspěvky neporušují soukromí nebo publicitu žádné třetí strany.
            </li>
            <li>
              Vaše Příspěvky neporušují žádný platný zákon týkající se dětské pornografie
              nebo jinak určené k ochraně zdraví nebo pohody nezletilých.
            </li>
            <li>
              Vaše Příspěvky neobsahují žádné urážlivé komentáře spojené s rasou,
              národnostním původem, pohlavím, sexuální preferencí nebo fyzickým
              postižením.
            </li>
            <li>
              Vaše Příspěvky jinak neporušují ani neodkazují na materiál, který porušuje
              jakékoli ustanovení těchto Právních podmínek nebo jakýkoli platný zákon nebo
              předpis.
            </li>
          </ul>
          <p className="mb-4">
            Jakékoli použití Služeb v rozporu s výše uvedeným porušuje tyto Právní podmínky
            a může mít za následek mimo jiné ukončení nebo pozastavení vašich práv
            používat Služby.
          </p>

          {/* 7. LICENCE K PŘÍSPĚVKŮM */}
          <h2 id="section7" className="text-2xl font-semibold mt-6 mb-4">
            7. LICENCE K PŘÍSPĚVKŮM
          </h2>
          <p className="mb-4">
            Zveřejněním vašich Příspěvků v jakékoli části Služeb nebo zpřístupněním
            Příspěvků Službám propojením vašeho účtu ze Služeb s jakýmkoli z vašich účtů na
            sociálních sítích automaticky udělujete a prohlašujete a zaručujete, že máte
            právo udělit nám neomezené, neomezené, neodvolatelné, trvalé, nevýhradní,
            převoditelné, bezplatné, plně zaplacené, celosvětové právo a licenci k hostování,
            používání, kopírování, zveřejňování, prodeji, dalšímu prodeji, publikování,
            vysílání, přejmenování, archivaci, ukládání, ukládání do mezipaměti, veřejnému
            provádění, veřejnému zobrazování, přeformátování, překladu, přenosu, výňatku (v
            celku nebo částečně) a distribuci takových Příspěvků (včetně mimo jiné vašeho
            obrazu a hlasu) pro jakýkoli účel, komerční, reklamní nebo jiný, a k přípravě
            odvozených děl z nebo začlenění do jiných děl takových Příspěvků a udělení a
            autorizaci sublicencí výše uvedeného. Použití a distribuce může probíhat v
            jakýchkoli mediálních formátech a prostřednictvím jakýchkoli mediálních kanálů.
          </p>
          <p className="mb-4">
            Tato licence se bude vztahovat na jakoukoli formu, médium nebo technologii
            nyní známou nebo později vyvinutou a zahrnuje naše použití vašeho jména,
            názvu společnosti a názvu franšízy, pokud je to relevantní, a jakýchkoli
            ochranných známek, servisních značek, obchodních názvů, log a osobních a
            komerčních obrázků, které poskytnete. Vzdáváte se všech morálních práv k vašim
            Příspěvkům a zaručujete, že morální práva nebyla jinak uplatněna ve vašich
            Příspěvcích.
          </p>
          <p className="mb-4">
            Nepožadujeme žádné vlastnictví nad vašimi Příspěvky. Zachováváte si plné
            vlastnictví všech vašich Příspěvků a jakýchkoli práv duševního vlastnictví nebo
            jiných vlastnických práv spojených s vašimi Příspěvky. Nejsme odpovědní za
            jakékoli prohlášení nebo reprezentace ve vašich Příspěvcích poskytnutých vámi v
            jakékoli oblasti na Službách. Jste výhradně odpovědní za své Příspěvky na
            Službách a výslovně souhlasíte s tím, že nás zprošťujete veškeré odpovědnosti a
            zdržíte se jakékoli právní akce proti nám ohledně vašich Příspěvků.
          </p>
          <p className="mb-4">
            Máme právo, podle našeho výhradního a absolutního uvážení, (1) upravit, začernit
            nebo jinak změnit jakékoli Příspěvky; (2) překategorizovat jakékoli Příspěvky,
            aby byly umístěny na vhodnějších místech na Službách; a (3) předběžně zkontrolovat
            nebo odstranit jakékoli Příspěvky kdykoli a z jakéhokoli důvodu, bez upozornění.
            Nemáme žádnou povinnost sledovat vaše Příspěvky.
          </p>

          {/* 8. SOCIÁLNÍ MÉDIA */}
          <h2 id="section8" className="text-2xl font-semibold mt-6 mb-4">
            8. SOCIÁLNÍ MÉDIA
          </h2>
          <p className="mb-4">
            V rámci funkcionality Služeb můžete propojit svůj účet s online účty, které
            máte u poskytovatelů služeb třetích stran (každý takový účet, "Účet třetí
            strany") buď: (1) poskytnutím informací o přihlášení k vašemu Účtu třetí
            strany prostřednictvím Služeb; nebo (2) umožněním nám přístupu k vašemu Účtu
            třetí strany, jak je to povoleno podle platných podmínek, které upravují vaše
            použití každého Účtu třetí strany. Prohlašujete a zaručujete, že jste
            oprávněni zveřejnit informace o přihlášení k vašemu Účtu třetí strany nám a/nebo
            nám poskytnout přístup k vašemu Účtu třetí strany, aniž byste porušili jakékoli
            podmínky, které upravují vaše použití příslušného Účtu třetí strany, a aniž by
            to zavazovalo nás k placení jakýchkoli poplatků nebo nás podrobilo jakýmkoli
            omezením použití uloženým poskytovatelem služeb třetí strany Účtu třetí strany.
            Udělením přístupu k jakýmkoli Účtům třetích stran chápete, že (1) můžeme
            přistupovat, zpřístupňovat a ukládat (pokud je to relevantní) jakýkoli obsah,
            který jste poskytli a uložili ve vašem Účtu třetí strany ("Obsah sociální
            sítě"), tak aby byl dostupný na a prostřednictvím Služeb prostřednictvím vašeho
            účtu, včetně bez omezení jakýchkoli seznamů přátel, a (2) můžeme odesílat a
            přijímat z vašeho Účtu třetí strany další informace v rozsahu, v jakém jste
            upozorněni, když propojíte svůj účet s Účtem třetí strany. V závislosti na
            Účtech třetích stran, které si vyberete, a v souladu s nastavením ochrany
            soukromí, které jste nastavili v těchto Účtech třetích stran, mohou být
            osobně identifikovatelné informace, které zveřejníte ve svých Účtech třetích
            stran, dostupné na a prostřednictvím vašeho účtu na Službách. Vezměte prosím na
            vědomí, že pokud se Účet třetí strany nebo související služba stane
            nedostupnou nebo náš přístup k takovému Účtu třetí strany je ukončen
            poskytovatelem služeb třetí strany, pak Obsah sociální sítě nemusí být dále
            dostupný na a prostřednictvím Služeb. Máte možnost kdykoli deaktivovat
            propojení mezi vaším účtem na Službách a vašimi Účty třetích stran. UPOZORŇUJEME,
            ŽE VÁŠ VZTAH S POSKYTOVATELI SLUŽEB TŘETÍCH STRAN SPOJENÝCH S VAŠIMI ÚČTY
            TŘETÍCH STRAN JE UPRAVEN VÝHRADNĚ VAŠÍ DOHODOU S TAKOVÝMI POSKYTOVATELI SLUŽEB
            TŘETÍCH STRAN. Nevyvíjíme žádné úsilí o přezkoumání jakéhokoli Obsahu sociální
            sítě pro jakýkoli účel, včetně, ale bez omezení na přesnost, zákonnost nebo
            neporušení, a nejsme odpovědní za jakýkoli Obsah sociální sítě. Berete na
            vědomí a souhlasíte s tím, že můžeme přistupovat k vašemu adresáři e-mailových
            adres spojenému s Účtem třetí strany a k vašemu seznamu kontaktů uloženému ve
            vašem mobilním zařízení nebo tabletu výhradně za účelem identifikace a
            informování vás o těch kontaktech, kteří se také zaregistrovali k používání
            Služeb. Můžete deaktivovat propojení mezi Službami a vaším Účtem třetí strany
            buď kontaktováním nás pomocí níže uvedených kontaktních informací, nebo
            prostřednictvím nastavení vašeho účtu (pokud je to relevantní). Pokusíme se
            odstranit jakékoli informace uložené na našich serverech, které byly získány
            prostřednictvím takového Účtu třetí strany, s výjimkou uživatelského jména a
            profilového obrázku, který se stane spojeným s vaším účtem.
          </p>

          {/* 9. SPRÁVA SLUŽEB */}
          <h2 id="section9" className="text-2xl font-semibold mt-6 mb-4">
            9. SPRÁVA SLUŽEB
          </h2>
          <p className="mb-4">
            Vyhrazujeme si právo, ale ne povinnost: (1) monitorovat Služby na porušení
            těchto Právních podmínek; (2) podniknout vhodné právní kroky proti komukoli,
            kdo podle našeho výhradního uvážení poruší zákon nebo tyto Právní podmínky,
            včetně bez omezení, nahlášení takového uživatele orgánům činným v trestním
            řízení; (3) podle našeho výhradního uvážení a bez omezení, odmítnout, omezit
            přístup k, omezit dostupnost nebo deaktivovat (v rozsahu technologicky
            proveditelném) jakékoli vaše Příspěvky nebo jakoukoli jejich část; (4) podle
            našeho výhradního uvážení a bez omezení, oznámení nebo odpovědnosti odstranit
            ze Služeb nebo jinak deaktivovat všechny soubory a obsah, které jsou nadměrné
            velikosti nebo jsou jakýmkoli způsobem zatěžující naše systémy; a (5) jinak
            spravovat Služby způsobem určeným k ochraně našich práv a majetku a k
            usnadnění řádného fungování Služeb.
          </p>

          {/* 10. ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ */}
          <h2 id="section10" className="text-2xl font-semibold mt-6 mb-4">
            10. ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ
          </h2>
          <p className="mb-4">
            Záleží nám na ochraně soukromí a bezpečnosti dat. Používáním Služeb souhlasíte
            s tím, že budete vázáni našimi Zásadami ochrany osobních údajů zveřejněnými na
            Službách, které jsou začleněny do těchto Právních podmínek. Upozorňujeme, že
            Služby jsou hostovány v České republice. Pokud přistupujete ke Službám z jiné
            oblasti světa s zákony nebo jinými požadavky týkajícími se sběru, použití nebo
            zveřejnění osobních údajů, které se liší od platných zákonů v České republice,
            pak pokračováním v používání Služeb přenášíte svá data do České republiky a
            výslovně souhlasíte s tím, aby byla vaše data přenesena a zpracována v České
            republice.
          </p>

          {/* 11. PORUŠENÍ AUTORSKÝCH PRÁV */}
          <h2 id="section11" className="text-2xl font-semibold mt-6 mb-4">
            11. PORUŠENÍ AUTORSKÝCH PRÁV
          </h2>
          <p className="mb-4">
            Respektujeme práva duševního vlastnictví ostatních. Pokud se domníváte, že
            jakýkoli materiál dostupný na nebo prostřednictvím Služeb porušuje jakékoli
            autorské právo, které vlastníte nebo kontrolujete, okamžitě nás prosím
            upozorněte pomocí níže uvedených kontaktních informací (oznámení). Kopie vašeho
            oznámení bude zaslána osobě, která zveřejnila nebo uložila materiál uvedený v
            oznámení. Upozorňujeme, že podle platného práva můžete být odpovědní za škody,
            pokud učiníte materiální zkreslení v oznámení. Pokud si tedy nejste jisti, že
            materiál umístěný na nebo odkazovaný Službami porušuje vaše autorské právo,
            měli byste zvážit nejprve kontaktování právníka.
          </p>

          {/* 12. DOBA TRVÁNÍ A UKONČENÍ */}
          <h2 id="section12" className="text-2xl font-semibold mt-6 mb-4">
            12. DOBA TRVÁNÍ A UKONČENÍ
          </h2>
          <p className="mb-4">
            Tyto Právní podmínky zůstávají v plné platnosti a účinnosti, dokud používáte
            Služby. <strong>
              BEZ OMEZENÍ JAKÉKOLI JINÉ USTANOVENÍ TĚCHTO PRÁVNÍCH PODMÍNEK SI
              VYHRAZUJEME PRÁVO PODLE NAŠEHO VÝHRADNÍHO UVÁŽENÍ A BEZ OZNÁMENÍ NEBO
              ODPOVĚDNOSTI ODMÍTNOUT PŘÍSTUP K A POUŽÍVÁNÍ SLUŽEB (VČETNĚ BLOKOVÁNÍ
              URČITÝCH IP ADRES) JAKÉKOLI OSOBĚ Z JAKÉHOKOLI DŮVODU NEBO BEZ DŮVODU,
              VČETNĚ BEZ OMEZENÍ PRO PORUŠENÍ JAKÉHOKOLI PROHLÁŠENÍ, ZÁRUKY NEBO
              DOHODY OBSAŽENÉ V TĚCHTO PRÁVNÍCH PODMÍNKÁCH NEBO JAKÉHOKOLI PLATNÉHO
              ZÁKONA NEBO PŘEDPISU.
            </strong>{' '}
            MŮŽEME UKONČIT VAŠE POUŽÍVÁNÍ NEBO ÚČAST NA SLUŽBÁCH NEBO SMAZAT VÁŠ ÚČET A
            JAKÝKOLI OBSAH NEBO INFORMACE, KTERÉ JSTE ZVEŘEJNILI KDYKOLI A BEZ UPOZORNĚNÍ,
            PODLE NAŠEHO VÝHRADNÍHO UVÁŽENÍ.
          </p>
          <p className="mb-4">
            Pokud ukončíme nebo pozastavíme váš účet z jakéhokoli důvodu, je vám zakázáno
            registrovat a vytvářet nový účet pod vaším jménem, falešným nebo vypůjčeným
            jménem nebo jménem jakékoli třetí strany, i když můžete jednat jménem třetí
            strany. Kromě ukončení nebo pozastavení vašeho účtu si vyhrazujeme právo
            podniknout příslušné právní kroky, včetně bez omezení stíhání občanskoprávních,
            trestních a soudních náhrad.
          </p>

          {/* 13. ÚPRAVY A PŘERUŠENÍ */}
          <h2 id="section13" className="text-2xl font-semibold mt-6 mb-4">
            13. ÚPRAVY A PŘERUŠENÍ
          </h2>
          <p className="mb-4">
            Vyhrazujeme si právo změnit, upravit nebo odstranit obsah Služeb kdykoli nebo
            z jakéhokoli důvodu podle našeho výhradního uvážení bez upozornění. Nemáme však
            žádnou povinnost aktualizovat jakékoli informace na našich Službách. Nebudeme
            odpovědní vám ani žádné třetí straně za jakékoli úpravy, změny ceny, pozastavení
            nebo ukončení Služeb.
          </p>
          <p className="mb-4">
            Nemůžeme zaručit, že Služby budou k dispozici po celou dobu. Můžeme zaznamenat
            hardwarové, softwarové nebo jiné problémy nebo potřebovat provést údržbu
            související se Službami, což má za následek přerušení, zpoždění nebo chyby.
            Vyhrazujeme si právo změnit, revidovat, aktualizovat, pozastavit, ukončit nebo
            jinak upravit Služby kdykoli nebo z jakéhokoli důvodu bez upozornění vám.
            Souhlasíte s tím, že neneseme žádnou odpovědnost za jakoukoli ztrátu, poškození
            nebo nepříjemnosti způsobené vaší neschopností přistupovat ke Službám nebo je
            používat během jakéhokoli výpadku nebo ukončení Služeb. Nic v těchto Právních
            podmínkách nebude vykládáno tak, že bychom byli povinni udržovat a podporovat
            Služby nebo poskytovat jakékoli opravy, aktualizace nebo vydání v souvislosti s
            nimi.
          </p>

          {/* 14. ROZHODNÉ PRÁVO */}
          <h2 id="section14" className="text-2xl font-semibold mt-6 mb-4">
            14. ROZHODNÉ PRÁVO
          </h2>
          <p className="mb-4">
            Tyto Právní podmínky se řídí a jsou vykládány v souladu se zákony České
            republiky a použití Úmluvy OSN o smlouvách o mezinárodní koupi zboží je
            výslovně vyloučeno. Pokud máte obvyklé bydliště v EU a jste spotřebitel, máte
            navíc ochranu poskytovanou vám závaznými ustanoveními zákona ve vaší zemi
            bydliště. SubwaySurveys a vy souhlasíte s tím, že se podřídíte nevýhradní
            jurisdikci soudů v Třebíči, což znamená, že můžete uplatnit nárok na ochranu
            svých spotřebitelských práv v souvislosti s těmito Právními podmínkami v České
            republice nebo v EU zemi, ve které žijete.
          </p>

          {/* 15. ŘEŠENÍ SPORŮ */}
          <h2 id="section15" className="text-2xl font-semibold mt-6 mb-4">
            15. ŘEŠENÍ SPORŮ
          </h2>
          <h3 className="text-xl font-semibold mt-4 mb-2">Neformální jednání</h3>
          <p className="mb-4">
            Aby bylo možné urychlit řešení a kontrolovat náklady jakéhokoli sporu,
            kontroverze nebo nároku souvisejícího s těmito Právními podmínkami (každý
            "Spor" a souhrnně "Spory") podaného vámi nebo námi (jednotlivě "Strana" a
            společně "Strany"), souhlasí Strany s tím, že se nejprve pokusí vyjednat jakýkoli
            Spor (s výjimkou Sporů výslovně uvedených níže) neformálně po dobu alespoň
            třiceti (30) dnů před zahájením arbitráže. Taková neformální jednání začínají
            po písemném oznámení jedné Strany druhé Straně.
          </p>
          <h3 className="text-xl font-semibold mt-4 mb-2">Závazná arbitráž</h3>
          <p className="mb-4">
            Jakýkoli spor vyplývající ze vztahů mezi Stranami těchto Právních podmínek
            bude rozhodnut jedním arbitrem, který bude vybrán v souladu s Arbitrážními a
            Vnitřními pravidly Evropského rozhodčího soudu, který je součástí Evropského
            centra pro arbitráž se sídlem ve Štrasburku, a která jsou v platnosti v době
            podání žádosti o arbitráž, a jejichž přijetí této klauzule představuje
            přijetí. Místo arbitráže bude Třebíč, Česká republika. Jazyk řízení bude
            čeština. Platná pravidla hmotného práva budou zákony České republiky.
          </p>
          <h3 className="text-xl font-semibold mt-4 mb-2">Omezení</h3>
          <p className="mb-4">
            Strany souhlasí s tím, že jakákoli arbitráž bude omezena na Spor mezi Stranami
            jednotlivě. V plném rozsahu povoleném zákonem (a) žádná arbitráž nebude
            spojena s žádným jiným řízením; (b) neexistuje žádné právo ani oprávnění k
            tomu, aby byl jakýkoli Spor rozhodován na základě hromadné žaloby nebo
            využívání postupů hromadné žaloby; a (c) neexistuje žádné právo ani oprávnění
            k tomu, aby byl jakýkoli Spor přiveden v údajné zastupitelské kapacitě jménem
            veřejnosti nebo jakýchkoli jiných osob.
          </p>
          <h3 className="text-xl font-semibold mt-4 mb-2">
            Výjimky z neformálních jednání a arbitráže
          </h3>
          <p className="mb-4">
            Strany souhlasí s tím, že následující Spory nepodléhají výše uvedeným
            ustanovením týkajícím se neformálních jednání závazné arbitráže: (a) jakékoli
            Spory usilující o vymáhání nebo ochranu nebo týkající se platnosti jakýchkoli
            práv duševního vlastnictví Strany; (b) jakýkoli Spor související s nebo
            vyplývající z obvinění z krádeže, pirátství, invaze do soukromí nebo
            neoprávněného použití; a (c) jakýkoli nárok na soudní zákaz. Pokud bude toto
            ustanovení shledáno nezákonným nebo nevynutitelným, pak žádná Strana nebude
            volit arbitráž jakéhokoli Sporu spadajícího do této části tohoto ustanovení,
            která byla shledána nezákonnou nebo nevynutitelnou, a takový Spor bude
            rozhodnut soudem s příslušnou jurisdikcí v rámci soudů uvedených výše pro
            jurisdikci, a Strany souhlasí s tím, že se podřídí osobní jurisdikci tohoto
            soudu.
          </p>

          {/* 16. OPRAVY */}
          <h2 id="section16" className="text-2xl font-semibold mt-6 mb-4">
            16. OPRAVY
          </h2>
          <p className="mb-4">
            Na Službách mohou být informace, které obsahují typografické chyby, nepřesnosti
            nebo opomenutí, včetně popisů, cen, dostupnosti a různých dalších informací.
            Vyhrazujeme si právo opravit jakékoli chyby, nepřesnosti nebo opomenutí a
            změnit nebo aktualizovat informace na Službách kdykoli, bez předchozího
            upozornění.
          </p>

          {/* 17. PROHLÁŠENÍ */}
          <h2 id="section17" className="text-2xl font-semibold mt-6 mb-4">
            17. PROHLÁŠENÍ
          </h2>
          <p className="mb-4">
            SLUŽBY JSOU POSKYTOVÁNY NA ZÁKLADĚ "TAK, JAK JSOU" A "JAK JSOU K DISPOZICI".
            SOUHLASÍTE S TÍM, ŽE POUŽÍVÁNÍ SLUŽEB BUDE NA VAŠE VÝHRADNÍ RIZIKO. V NEJVĚTŠÍM
            ROZSAHU POVOLENÉM ZÁKONEM ODMÍTÁME VEŠKERÉ ZÁRUKY, VÝSLOVNÉ NEBO IMPLIKOVANÉ, V
            SOUVISLOSTI SE SLUŽBAMI A VAŠÍM POUŽÍVÁNÍM NICH, VČETNĚ, ALE BEZ OMEZENÍ NA
            IMPLIKOVANÉ ZÁRUKY OBCHODOVATELNOSTI, VHODNOSTI PRO URČITÝ ÚČEL A
            NEPORUŠENÍ. NEPOSKYTUJEME ŽÁDNÉ ZÁRUKY ANI PROHLÁŠENÍ O PŘESNOSTI NEBO
            KOMPLETNOSTI OBSAHU SLUŽEB NEBO OBSAHU JAKÝCHKOLI WEBOVÝCH STRÁNEK NEBO
            MOBILNÍCH APLIKACÍ SPOJENÝCH SE SLUŽBAMI A NEPŘEBÍRÁME ŽÁDNOU ODPOVĚDNOST ZA
            JAKÉKOLI (1) CHYBY, OMYLY NEBO NEPŘESNOSTI OBSAHU A MATERIÁLŮ, (2) OSOBNÍ
            ZRANĚNÍ NEBO POŠKOZENÍ MAJETKU JAKÉHOKOLI DRUHU VYPLÝVAJÍCÍ Z VAŠEHO PŘÍSTUPU
            KE SLUŽBÁM A JEJICH POUŽÍVÁNÍ, (3) JAKÝKOLI NEOPRÁVNĚNÝ PŘÍSTUP K NEBO POUŽITÍ
            NAŠICH BEZPEČNÝCH SERVERŮ A/NEBO JAKÝCHKOLIV A VEŠKERÝCH OSOBNÍCH INFORMACÍ A/NEBO
            FINANČNÍCH INFORMACÍ V NICH ULOŽENÝCH, (4) JAKÉKOLI PŘERUŠENÍ NEBO UKONČENÍ
            PŘENOSU DO NEBO ZE SLUŽEB, (5) JAKÉKOLI CHYBY, VIRY, TROJSKÉ KONĚ NEBO PODOBNÉ,
            KTERÉ MOHOU BÝT PŘENESENY NA NEBO PROSTŘEDNICTVÍM SLUŽEB JAKOUKOLI TŘETÍ
            STRANOU, A/NEBO (6) JAKÉKOLI CHYBY NEBO OPOMENUTÍ V JAKÉMKOLI OBSAHU A
            MATERIÁLECH NEBO ZA JAKOUKOLI ZTRÁTU NEBO POŠKOZENÍ JAKÉHOKOLI DRUHU VZNIKLÉ V
            DŮSLEDKU POUŽITÍ JAKÉHOKOLI OBSAHU ZVEŘEJNĚNÉHO, PŘENESENÉHO NEBO JINAK
            ZPŘÍSTUPNĚNÉHO PROSTŘEDNICTVÍM SLUŽEB. NEZARUČUJEME, NEDOPORUČUJEME,
            NEZARUČUJEME ANI NEPŘEBÍRÁME ODPOVĚDNOST ZA JAKÝKOLI PRODUKT NEBO SLUŽBU
            INZEROVANOU NEBO NABÍZENOU TŘETÍ STRANOU PROSTŘEDNICTVÍM SLUŽEB, JAKÉKOLI
            HYPERTEXTOVÉ ODKAZOVANÉ WEBOVÉ STRÁNKY NEBO JAKÉKOLI WEBOVÉ STRÁNKY NEBO
            MOBILNÍ APLIKACE UVEDENÉ V JAKÉKOLI REKLAMĚ NEBO JINÉ REKLAMĚ A NEBUDEME
            STRANOU NEBO JAKÝMKOLI ZPŮSOBEM ZODPOVĚDNÍ ZA MONITOROVÁNÍ JAKÉKOLI TRANSAKCE
            MEZI VÁMI A JAKÝMIKOLI POSKYTOVATELI PRODUKTŮ NEBO SLUŽEB TŘETÍCH STRAN. JAKO
            PŘI NÁKUPU PRODUKTU NEBO SLUŽBY PROSTŘEDNICTVÍM JAKÉHOKOLI PROSTŘEDKU NEBO V
            JAKÉMKOLI PROSTŘEDÍ BYSTE MĚLI POUŽÍVAT SVŮJ NEJLEPŠÍ ROZSUDEK A UPLATŇOVAT
            OPATRNOST, KDE JE TO VHODNÉ.
          </p>

          {/* 18. OMEZENÍ ODPOVĚDNOSTI */}
          <h2 id="section18" className="text-2xl font-semibold mt-6 mb-4">
            18. OMEZENÍ ODPOVĚDNOSTI
          </h2>
          <p className="mb-4">
            V ŽÁDNÉM PŘÍPADĚ NEBUDEME MY ANI NAŠI ŘEDITELÉ, ZAMĚSTNANCI NEBO AGENTI
            ODPOVĚDNÍ VÁM NEBO JAKÉKOLI TŘETÍ STRANĚ ZA JAKÉKOLI PŘÍMÉ, NEPŘÍMÉ,
            NÁSLEDNÉ, EXEMPLÁRNÍ, NÁHODNÉ, ZVLÁŠTNÍ NEBO TRESTNÍ ŠKODY, VČETNĚ UŠLÉHO
            ZISKU, UŠLÝCH PŘÍJMŮ, ZTRÁTY DAT NEBO JINÝCH ŠKOD VYPLÝVAJÍCÍCH Z VAŠEHO
            POUŽÍVÁNÍ SLUŽEB, I KDYŽ JSME BYLI UPOZORNĚNI NA MOŽNOST TAKOVÝCH ŠKOD.
          </p>

          {/* 19. ODŠKODNĚNÍ */}
          <h2 id="section19" className="text-2xl font-semibold mt-6 mb-4">
            19. ODŠKODNĚNÍ
          </h2>
          <p className="mb-4">
            Souhlasíte s tím, že nás budete bránit, odškodňovat a držet bez újmy, včetně
            našich dceřiných společností, přidružených společností a všech našich příslušných
            úředníků, agentů, partnerů a zaměstnanců, od a proti jakékoli ztrátě, škodě,
            odpovědnosti, nároku nebo poptávce, včetně přiměřených poplatků a výdajů
            advokátů, učiněných jakoukoli třetí stranou z důvodu nebo vyplývajících z: (1)
            vašich Příspěvků; (2) používání Služeb; (3) porušení těchto Právních podmínek;
            (4) jakéhokoli porušení vašich prohlášení a záruk uvedených v těchto Právních
            podmínkách; (5) vašeho porušení práv třetí strany, včetně, ale bez omezení na
            práva duševního vlastnictví; nebo (6) jakéhokoli zjevného škodlivého jednání
            vůči jinému uživateli Služeb, s nímž jste se spojili prostřednictvím Služeb.
            Bez ohledu na výše uvedené si vyhrazujeme právo, na vaše náklady, převzít
            výhradní obhajobu a kontrolu jakékoli záležitosti, pro kterou jste povinni nás
            odškodnit, a souhlasíte s tím, že budete na své náklady spolupracovat při naší
            obhajobě takových nároků. Vynaložíme přiměřené úsilí, abychom vás upozornili na
            jakýkoli takový nárok, akci nebo řízení, které podléhá tomuto odškodnění, jakmile
            se o něm dozvíme.
          </p>

          {/* 20. UŽIVATELSKÁ DATA */}
          <h2 id="section20" className="text-2xl font-semibold mt-6 mb-4">
            20. UŽIVATELSKÁ DATA
          </h2>
          <p className="mb-4">
            Budeme udržovat určitá data, která přenesete do Služeb za účelem správy
            výkonu Služeb, stejně jako data týkající se vašeho používání Služeb. Přestože
            provádíme pravidelné rutinní zálohy dat, jste výhradně odpovědní za všechna data,
            která přenesete nebo která se týkají jakékoli aktivity, kterou jste podnikli
            při používání Služeb. Souhlasíte s tím, že neneseme žádnou odpovědnost vůči vám
            za jakoukoli ztrátu nebo poškození takových dat, a tímto se vzdáváte jakéhokoli
            práva na žalobu proti nám vyplývající z takové ztráty nebo poškození těchto
            dat.
          </p>

          {/* 21. ELEKTRONICKÁ KOMUNIKACE, TRANSAKCE A PODPISY */}
          <h2 id="section21" className="text-2xl font-semibold mt-6 mb-4">
            21. ELEKTRONICKÁ KOMUNIKACE, TRANSAKCE A PODPISY
          </h2>
          <p className="mb-4">
            Návštěva Služeb, zasílání e-mailů a vyplňování online formulářů představuje
            elektronickou komunikaci. Souhlasíte s přijímáním elektronické komunikace a
            souhlasíte s tím, že všechny dohody, oznámení, zveřejnění a další komunikace,
            které vám poskytujeme elektronicky, prostřednictvím e-mailu a na Službách,
            splňují jakýkoli zákonný požadavek, aby taková komunikace byla v písemné formě.
            TÍMTO SOUHLASÍTE S POUŽITÍM ELEKTRONICKÝCH PODPISŮ, SMLOUV, OBJEDNÁVEK A
            JINÝCH ZÁZNAMŮ A S ELEKTRONICKÝM DORUČOVÁNÍM OZNÁMENÍ, ZÁSAD A ZÁZNAMŮ
            TRANSAKCÍ ZAHÁJENÝCH NEBO DOKONČENÝCH NÁMI NEBO PROSTŘEDNICTVÍM SLUŽEB. Tímto
            se vzdáváte jakýchkoli práv nebo požadavků podle jakýchkoli zákonů, předpisů,
            pravidel, nařízení nebo jiných zákonů v jakékoli jurisdikci, které vyžadují
            originální podpis nebo doručení či uchování neelektronických záznamů, nebo k
            platbám nebo udělování kreditů jakýmikoli jinými prostředky než elektronickými
            prostředky.
          </p>

          {/* 22. RŮZNÉ */}
          <h2 id="section22" className="text-2xl font-semibold mt-6 mb-4">
            22. RŮZNÉ
          </h2>
          <p className="mb-4">
            Tyto Právní podmínky a jakékoli zásady nebo provozní pravidla zveřejněné námi
            na Službách nebo v souvislosti se Službami představují úplnou dohodu a
            porozumění mezi vámi a námi. Naše neuplatnění nebo nevynucení jakéhokoli práva
            nebo ustanovení těchto Právních podmínek nepředstavuje vzdání se takového práva
            nebo ustanovení. Tyto Právní podmínky působí v nejširším rozsahu povoleném
            zákonem. Můžeme převést jakákoli nebo všechna naše práva a povinnosti na
            ostatní kdykoli. Nebudeme odpovědní za jakoukoli ztrátu, poškození, zpoždění
            nebo selhání jednání způsobené jakoukoli příčinou mimo naši přiměřenou kontrolu.
            Pokud je jakékoli ustanovení nebo část ustanovení těchto Právních podmínek
            shledáno nezákonným, neplatným nebo nevynutitelným, toto ustanovení nebo část
            ustanovení je považováno za oddělitelné od těchto Právních podmínek a nemá vliv
            na platnost a vynutitelnost zbývajících ustanovení. Mezi vámi a námi není
            vytvořen žádný společný podnik, partnerství, zaměstnanecký nebo agenturní vztah
            v důsledku těchto Právních podmínek nebo používání Služeb. Souhlasíte s tím, že
            tyto Právní podmínky nebudou vykládány proti nám z důvodu jejich vytvoření. Tímto
            se vzdáváte jakýchkoli a všech obhajob, které můžete mít na základě elektronické
            formy těchto Právních podmínek a nedostatku podpisu stran zde k provedení těchto
            Právních podmínek.
          </p>

          {/* 23. KONTAKTUJTE NÁS */}
          <h2 id="section23" className="text-2xl font-semibold mt-6 mb-4">
            23. KONTAKTUJTE NÁS
          </h2>
          <p className="mb-4">
            Pokud chcete vyřešit stížnost týkající se Služeb nebo získat další informace o
            používání Služeb, kontaktujte nás prosím na:
          </p>
          <p className="mb-4">
            <strong>SubwaySurveys</strong>
            <br />
            <a href="mailto:urbanekd.05@spst.eu" className="text-accentYellow">
              urbanekd.05@spst.eu
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsOfService;
