// client/src/Results.js

import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AuthContext } from './AuthContext';
import { FaCopy, FaQrcode, FaTimes, FaArrowLeft } from 'react-icons/fa';
import { QRCodeSVG } from 'qrcode.react';
import { io } from 'socket.io-client';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function Results() {
  const { slug } = useParams();
  const [survey, setSurvey] = useState(null);
  const [message, setMessage] = useState('');
  const { user } = useContext(AuthContext);
  const [isOwner, setIsOwner] = useState(false);

  const [copied, setCopied] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);

  const [animationTrigger, setAnimationTrigger] = useState(false);

  useEffect(() => {
    // Získání URL pro Socket.IO z environmentální proměnné nebo default na 'http://localhost:5000'
    const socketUrl = process.env.REACT_APP_SOCKET_IO_URL || 'http://localhost:5000';
    
    // Inicializace Socket.IO klienta
    const socket = io(socketUrl, {
      transports: ['websocket'], // Použití pouze websocket transportu
      withCredentials: true, // Pokud používáte credentials
    });

    const fetchSurvey = async () => {
      try {
        const response = await axios.get(`/api/surveys/${slug}/results`);
        const fetchedSurvey = response.data;
        setSurvey(fetchedSurvey);

        if (user && fetchedSurvey.userId === user.id) { // Opraveno z user.userId na user.id
          setIsOwner(true);
        }

        setTimeout(() => {
          setAnimationTrigger(true);
        }, 100);
      } catch (error) {
        console.error(error);
        setMessage('Chyba při načítání výsledků ankety.');
      }
    };

    fetchSurvey();

    // Nastavení listeneru pro 'voteUpdate' event
    socket.on('voteUpdate', (updatedSurvey) => {
      if (updatedSurvey.slug === slug) {
        setSurvey(updatedSurvey);
        setAnimationTrigger(false);
        setTimeout(() => {
          setAnimationTrigger(true);
        }, 100);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [slug, user]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}. ${year}, ${hours}:${minutes}`;
  };

  const handleCopyLink = () => {
    const surveyUrl = `${window.location.origin}/vote/${survey.slug}`;
    navigator.clipboard.writeText(surveyUrl);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleToggleQRCode = () => {
    setShowQRCode(!showQRCode);
  };

  if (!survey) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-200 dark:bg-gray-900 text-black dark:text-white">
        Načítám výsledky ankety...
      </div>
    );
  }

  const totalVotes = survey.options.reduce((acc, option) => acc + option.votes, 0);

  const backgroundColors = [
    'rgba(255, 99, 132, 0.9)',
    'rgba(54, 162, 235, 0.9)',
    'rgba(255, 206, 86, 0.9)',
    'rgba(75, 192, 192, 0.9)',
    'rgba(153, 102, 255, 0.9)',
  ];

  const borderColors = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
  ];

  const data = {
    labels: survey.options.map((option) => option.text),
    datasets: [
      {
        label: 'Počet hlasů',
        data: survey.options.map((option) => option.votes),
        backgroundColor: survey.options.map((_, index) => backgroundColors[index % backgroundColors.length]),
        borderColor: survey.options.map((_, index) => borderColors[index % borderColors.length]),
        borderWidth: 1,
      },
    ],
  };

  const optionsChart = {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Výsledky ankety',
        font: {
          size: 20,
        },
        color: 'currentColor',
      },
      datalabels: {
        display: true,
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          return label;
        },
        color: '#fff',
        font: {
          weight: 'bold',
        },
        anchor: 'end',
        align: 'start',
        offset: 0,
        rotation: (context) => {
          const meta = context.chart.getDatasetMeta(context.datasetIndex);
          const element = meta.data[context.dataIndex];

          if (!element) {
            return 0;
          }

          const { startAngle, endAngle } = element;
          const angle = (startAngle + endAngle) / 2;
          let rotation = (angle * 180) / Math.PI - 90;

          if (angle > Math.PI / 2 && angle < (3 * Math.PI) / 2) {
            rotation += 180;
          }

          return rotation;
        },
      },
    },
  };

  const surveyUrl = `${window.location.origin}/vote/${survey.slug}`;

  const isDarkMode = document.documentElement.classList.contains('dark');

  return (
    <div className="min-h-screen bg-gray-200 dark:bg-gray-900 px-4 py-8">
      {/* Hlavní kontejner */}
      <div className="max-w-[900px] mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 mb-8 border-t-4 border-accentYellow">
        <h1 className="text-3xl font-bold text-black dark:text-white">{survey.title}</h1>

        <p className="text-gray-600 dark:text-gray-300 mt-1 mb-8">
          Vytvořeno: <strong>{formatDate(survey.createdAt)}</strong>
        </p>

        {survey.description && (
          <p className="mt-4 mb-6 text-black dark:text-white">{survey.description}</p>
        )}

        {message && <div className="mb-4 text-red-500">{message}</div>}

        {totalVotes === 0 ? (
          <p className="text-black dark:text-white">Zatím žádné hlasy.</p>
        ) : (
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2 md:pr-4">
              {survey.options.map((option, index) => {
                const votePercentage = totalVotes
                  ? ((option.votes / totalVotes) * 100).toFixed(2)
                  : 0;
                const bgColor = backgroundColors[index % backgroundColors.length];

                const width = animationTrigger ? `${votePercentage}%` : '0%';

                return (
                  <div key={option.id} className="mb-4">
                    <div className="flex justify-between mb-1">
                      <span className="font-semibold text-black dark:text-white">{option.text}</span>
                      <span className="text-black dark:text-white">
                        {option.votes} hlasů ({votePercentage}%)
                      </span>
                    </div>
                    <div className="w-full bg-gray-300 dark:bg-gray-700 rounded-full h-6">
                      <div
                        className="h-6 rounded-full"
                        style={{
                          width: width,
                          backgroundColor: bgColor,
                          transition: 'width 1s ease-in-out',
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
              <hr className="my-4 border-t border-gray-300 dark:border-gray-600" />
              <div className="text-left font-semibold text-black dark:text-white">
                Celkový počet hlasů: {totalVotes}
              </div>
            </div>

            <div className="md:w-1/2 md:pl-4 mt-8 md:mt-0">
              <Pie data={data} options={optionsChart} />
            </div>
          </div>
        )}
        <div className="mt-8 ">
          <Link
            to={`/vote/${survey.slug}`}
            className="inline-flex items-center bg-accentYellow hover:bg-yellow-500 text-gray-800 font-semibold py-2 px-3 rounded transition duration-200 group "
          >
            <FaArrowLeft className="mr-2 transition-transform duration-300 transform group-hover:-translate-x-1 " />
            Vrátit se k hlasování
          </Link>
        </div>
      </div>

      {isOwner && (
        <div className="max-w-[900px] mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 mt-8 relative border-t-4 border-accentYellow">
          <h2 className="text-2xl font-bold mb-4 text-black dark:text-white">Sdílejte tuto anketu</h2>
          {copied && (
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full bg-accentYellow text-black py-2 px-4 rounded opacity-100 animate-fade-out-up">
              Odkaz byl zkopírován
            </div>
          )}
          <div className="mb-4 text-black dark:text-white">Odkaz pro sdílení ankety:</div>
          <div className="relative">
            <input
              type="text"
              readOnly
              value={surveyUrl}
              className="border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-black dark:text-white p-3 w-full pr-24 rounded-lg focus:outline-none focus:ring-2 focus:ring-accentYellow"
            />
            <button
              onClick={handleCopyLink}
              className="absolute inset-y-0 right-12 flex items-center px-3 text-gray-500 dark:text-gray-300 hover:text-accentYellow dark:hover:text-accentYellow transition-colors duration-200 focus:outline-none group"
              aria-label="Kopírovat odkaz"
            >
              <FaCopy size={20} />
              <span className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-500 text-white text-xs rounded py-1 px-2">
                Kopírovat odkaz
              </span>
            </button>
            <button
              onClick={handleToggleQRCode}
              className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 dark:text-gray-300 hover:text-accentYellow dark:hover:text-accentYellow transition-colors duration-200 focus:outline-none group"
              aria-label="Zobrazit QR kód"
            >
              <FaQrcode size={20} />
              <span className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-500 text-white text-xs rounded py-1 px-2">
                Zobrazit QR kód
              </span>
            </button>
          </div>
          {showQRCode && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
              onClick={handleToggleQRCode}
            >
              <div
                className="bg-white dark:bg-gray-800 p-6 rounded-lg relative max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  onClick={handleToggleQRCode}
                  className="absolute top-2 right-2 text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-500 focus:outline-none"
                  aria-label="Zavřít"
                >
                  <FaTimes size={24} />
                </button>
                <h2 className="text-xl font-bold mb-4 text-black dark:text-white text-center">QR kód ankety</h2>
                <QRCodeSVG
                  value={surveyUrl}
                  size={300}
                  fgColor={isDarkMode ? '#FFFFFF' : '#000000'}
                  bgColor="transparent"
                  className="w-full h-auto"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Results;
