// src/App.js

import React, { useContext, useState } from 'react';
import { Routes, Route, Link, useNavigate, Navigate } from 'react-router-dom';
import EmailForm from './EmailForm';
import Surveys from './Surveys';
import SurveyList from './SurveyList';
import Vote from './Vote';
import Results from './Results';
import ProtectedRoute from './ProtectedRoute';
import { AuthContext } from './AuthContext';
import { ThemeContext } from './ThemeContext';
import { FaSun, FaMoon, FaBars, FaTimes } from 'react-icons/fa';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import CookiePolicy from './CookiePolicy';
import Footer from './Footer';

function App() {
  return (
    <div className="min-h-screen flex flex-col bg-white dark:bg-gray-900">
      <Navigation />
      <div className="flex-grow bg-gray-100 dark:bg-gray-900">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<EmailForm />} />
          <Route
            path="/surveys"
            element={
              <ProtectedRoute>
                <Surveys />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-surveys"
            element={
              <ProtectedRoute>
                <SurveyList />
              </ProtectedRoute>
            }
          />
          <Route path="/vote/:slug" element={<Vote />} />
          <Route path="/results/:slug" element={<Results />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

function Navigation() {
  const { token, logout } = useContext(AuthContext);
  const { theme, setTheme } = useContext(ThemeContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="bg-white dark:bg-gray-800 text-black dark:text-white shadow-md relative">
      <div className="container mx-auto px-4 py-3 max-w-7xl flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img src="/ss.png" alt="Logo" className="h-12 w-auto mr-2" />
        </Link>

        {/* Desktop Navigation Links and Theme Toggle */}
        <div className="hidden md:flex items-center space-x-6">
          {token ? (
            <>
              <Link to="/surveys" className="hover-underline text-base">
                Vytvořit anketu
              </Link>
              <Link to="/my-surveys" className="hover-underline text-base">
                Moje Ankety
              </Link>
              <button onClick={handleLogout} className="hover-underline text-base">
                Odhlásit se
              </button>
            </>
          ) : (
            <Link to="/auth" className="hover-underline text-base">
              Přihlášení/Registrace
            </Link>
          )}
          <button
            onClick={toggleTheme}
            className="ml-4 focus:outline-none"
            aria-label="Přepnout mód"
          >
            {theme === 'light' ? (
              <FaSun
                size={24}
                className="text-yellow-500 transition-transform duration-300 transform hover:rotate-180"
              />
            ) : (
              <FaMoon
                size={24}
                className="text-yellow-500 transition-transform duration-300 transform hover:rotate-180"
              />
            )}
          </button>
        </div>

        {/* Mobile Theme Toggle and Hamburger Icon */}
        <div className="flex items-center space-x-4 md:hidden">
          <button
            onClick={toggleTheme}
            className="focus:outline-none"
            aria-label="Toggle Theme"
          >
            {theme === 'light' ? (
              <FaSun
                size={24}
                className="text-yellow-500 transition-transform duration-300 transform hover:rotate-180"
              />
            ) : (
              <FaMoon
                size={24}
                className="text-yellow-500 transition-transform duration-300 transform hover:rotate-180"
              />
            )}
          </button>
          <button
            onClick={toggleMenu}
            className="focus:outline-none"
            aria-label="Toggle Menu"
          >
            {menuOpen ? (
              <FaTimes size={24} className="text-gray-800 dark:text-gray-100" />
            ) : (
              <FaBars size={24} className="text-gray-800 dark:text-gray-100" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`${
          menuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
        } md:hidden absolute inset-x-0 bg-white dark:bg-gray-800 text-black dark:text-white space-y-4 p-4 transition-all duration-300 ease-in-out z-10 overflow-hidden`}
      >
        {token ? (
          <>
            <Link to="/surveys" className="block hover-underline text-base" onClick={toggleMenu}>
              Vytvořit anketu
            </Link>
            <Link to="/my-surveys" className="block hover-underline text-base" onClick={toggleMenu}>
              Moje Ankety
            </Link>
            <button
              onClick={() => {
                handleLogout();
                toggleMenu();
              }}
              className="block hover-underline text-base"
            >
              Odhlásit se
            </button>
          </>
        ) : (
          <Link to="/auth" className="block hover-underline text-base" onClick={toggleMenu}>
            Přihlášení/Registrace
          </Link>
        )}
      </div>
    </nav>
  );
}

function Home() {
  const { theme } = useContext(ThemeContext);

  // Obrázky pro light a dark mód
  const lightImages = {
    topRight: '/anketalight.PNG',
    bottomLeft: '/vysledkylight.PNG',
  };

  const darkImages = {
    topRight: '/anketadark.PNG',
    bottomLeft: '/vysledkydark.PNG',
  };

  const images = theme === 'light' ? lightImages : darkImages;

  return (
    <div className="bg-gray-100 dark:bg-gray-900 pt-8 flex flex-col items-center">
      {/* Hero Section */}
      <div
        className="max-w-7xl w-full p-8 rounded-lg shadow-lg relative flex flex-col justify-center items-start mb-16 h-[600px]"
        style={{
          backgroundImage: "url('/ssuvod.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60 rounded-lg"></div>
        <div className="relative z-10 text-left max-w-full md:max-w-[50%] px-8">
          <h1 className="text-5xl md:text-6xl font-bold mb-6 leading-tight text-white">
            Vytvořte anketu
            <br />
            během vteřiny
          </h1>
          <p className="text-xl md:text-2xl mb-6 text-white">
            Chcete zjistit názor kolegů na termín schůzky nebo rozhodnout o pátečním
            programu? Vytvořte anketu a hlasujte.
          </p>
          <Link
            to="/surveys"
            className="inline-block bg-accentYellow hover:bg-yellow-500 text-black font-semibold text-lg md:text-xl py-4 px-8 rounded transition duration-200"
          >
            Vytvořit anketu
          </Link>
        </div>
      </div>

      {/* Section 1: Text vlevo, obrázek vpravo */}
      <div className="max-w-7xl w-full flex flex-col md:flex-row items-center mb-16 px-4">
        <div className="text-black dark:text-white mb-6 md:mb-0 md:mr-8 md:w-1/2 px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Proč používat Subway Surveys?</h2>
          <p className="text-lg md:text-xl">
            Subway Surveys je nástroj pro rychlé a jednoduché vytváření anket, který
            umožňuje rozhodovat efektivně a shromažďovat názory vašeho týmu nebo
            přátel.
          </p>
        </div>
        <div className="md:w-1/2 w-full px-4">
          <img
            src={images.topRight}
            alt="Proč používat Subway Surveys"
            className="w-full h-auto rounded-lg shadow-md object-contain"
            loading="lazy"
          />
        </div>
      </div>

      {/* Section 2: Obrázek vlevo, text vpravo */}
      <div className="max-w-7xl w-full flex flex-col md:flex-row items-center mb-16 px-4">
        <div className="md:w-1/2 w-full px-4">
          <img
            src={images.bottomLeft}
            alt="Získejte výsledky okamžitě"
            className="w-full h-auto rounded-lg shadow-md object-contain"
            loading="lazy"
          />
        </div>
        <div className="text-black dark:text-white md:ml-8 mt-6 md:mt-0 md:w-1/2 px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Získejte výsledky okamžitě</h2>
          <p className="text-lg md:text-xl">
            Naše platforma umožňuje sledovat výsledky v reálném čase, takže nikdy
            neztratíte přehled o tom, co si ostatní myslí.
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
