// client/src/EmailForm.js

import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { FaGoogle, FaEye, FaEyeSlash, FaExclamation, FaCheck } from 'react-icons/fa';

function EmailForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, loginWithToken } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    password: '',
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false); // Přidáno pro určení typu zprávy

  useEffect(() => {
    const processGoogleLogin = async () => {
      const query = new URLSearchParams(location.search);
      const token = query.get('token');

      if (token) {
        try {
          await loginWithToken(token); // Zpracujeme token
          navigate('/my-surveys'); // Přesměrujeme po úspěchu
        } catch (err) {
          console.error('Chyba při přihlášení přes Google:', err);
          setMessage('Nepodařilo se přihlásit pomocí Google. Zkuste to znovu.');
          setIsSuccess(false);
        }
      }
    };

    processGoogleLogin();
  }, [location, loginWithToken, navigate]);

  const checkEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/check-email', { email });
      if (response.data.exists) {
        setStep(2); // Uživatel existuje, zobrazí se přihlašovací formulář
      } else {
        setStep(3); // Uživatel neexistuje, zobrazí se registrační formulář
      }
    } catch (error) {
      console.error(error);
      setMessage('Chyba při ověřování e-mailu. Zkuste to prosím znovu.');
      setIsSuccess(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      await login(email, formData.password, rememberMe);
      setMessage('Přihlášení proběhlo úspěšně!');
      setIsSuccess(true);
      navigate('/my-surveys');
    } catch (error) {
      console.error(error);
      setMessage(
        error.response?.data?.message ||
          'Chyba při přihlášení. Zkontrolujte své údaje.'
      );
      setIsSuccess(false);
    }
  };

  const registerUser = async (e) => {
    e.preventDefault();

    // Klientská validace hesla
    if (formData.password.length < 8) {
      setMessage('Heslo musí mít alespoň 8 znaků.');
      setIsSuccess(false);
      return;
    }

    try {
      await axios.post('/api/register', {
        name: formData.name,
        email,
        password: formData.password,
      });

      // Automatické přihlášení uživatele a přesměrování
      await login(email, formData.password, rememberMe);
      setMessage('Registrace proběhla úspěšně!');
      setIsSuccess(true);
      navigate('/surveys');
    } catch (error) {
      console.error(error);
      setMessage(
        error.response?.data?.message ||
          'Chyba při registraci. Zkuste to prosím znovu.'
      );
      setIsSuccess(false);
    }
  };

  const socialLogin = (provider) => {
    window.location.href = `/auth/${provider}`;
  };

  return (
    <div className="flex flex-col items-center justify-start bg-gray-100 dark:bg-gray-900 pt-8">
      {/* Logo Section */}
      <div className="flex justify-center mb-6 mt-4">
        <img src="/ss.png" alt="Logo" className="h-32 w-auto" />
      </div>

      {/* Form Container */}
      <div className="bg-white dark:bg-gray-800 p-8 rounded shadow-md w-full max-w-md border-t-4 border-accentYellow">
        {step === 1 && (
          <form onSubmit={checkEmail}>
            <h2 className="text-2xl font-bold mb-6 text-center text-black dark:text-white">
              Zadejte svůj e-mail
            </h2>

            {/* Chybová zpráva pro krok 1 */}
            {message && (
              <div className="mb-4 flex items-center justify-center">
                <div
                  className={`flex items-center py-2 px-3 rounded ${
                    isSuccess
                      ? 'bg-green-200 dark:bg-green-700 text-green-700 dark:text-green-300'
                      : 'bg-red-200 dark:bg-red-700 text-red-700 dark:text-red-300'
                  }`}
                >
                  {isSuccess ? (
                    <FaCheck className="mr-2" />
                  ) : (
                    <FaExclamation className="mr-2" />
                  )}
                  <span className="font-semibold">{message}</span>
                </div>
              </div>
            )}

            <div className="mb-4">
              <label
                className="block text-gray-700 dark:text-gray-300 mb-2"
                htmlFor="email"
              >
                E-mail
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="jan.novak@email.cz"
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-gray-100 dark:bg-gray-700 text-black dark:text-white
                focus:outline-none focus:border-accentYellow dark:focus:border-accentYellow-dark"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-accentYellow text-gray-800 dark:bg-accentYellow-dark py-2 rounded transition duration-200
              hover:bg-accentYellow-hover dark:hover:bg-accentYellow-hover"
            >
              Pokračovat
            </button>

            <div className="flex items-center my-6">
              <hr className="flex-grow border-t border-gray-300 dark:border-gray-600" />
              <span className="mx-4 text-gray-500 dark:text-gray-400">
                nebo se přihlaste
              </span>
              <hr className="flex-grow border-t border-gray-300 dark:border-gray-600" />
            </div>

            <div className="flex flex-col space-y-4">
              <button
                type="button"
                onClick={() => socialLogin('google')}
                className="flex items-center justify-center w-full py-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-black dark:text-white
                hover:bg-gray-200 dark:hover:bg-gray-600 transition duration-200"
              >
                <FaGoogle className="mr-2" />
                Přihlásit se pomocí Google
              </button>
            </div>
          </form>
        )}

        {(step === 2 || step === 3) && (
          <form onSubmit={step === 2 ? loginUser : registerUser}>
            <h2 className="text-2xl font-bold mb-6 text-center text-black dark:text-white">
              {step === 2 ? 'Přihlášení' : 'Registrace'}
            </h2>

            {/* Chybová zpráva pro kroky 2 a 3 */}
            {message && (
              <div className="mb-4 flex items-center justify-center">
                <div
                  className={`flex items-center py-2 px-3 rounded ${
                    isSuccess
                      ? 'bg-green-200 dark:bg-green-700 text-green-700 dark:text-green-300'
                      : 'bg-red-200 dark:bg-red-700 text-red-700 dark:text-red-300'
                  }`}
                >
                  {isSuccess ? (
                    <FaCheck className="mr-2" />
                  ) : (
                    <FaExclamation className="mr-2" />
                  )}
                  <span className="font-semibold">{message}</span>
                </div>
              </div>
            )}

            <div className="mb-4">
              <label
                className="block text-gray-700 dark:text-gray-300 mb-2"
                htmlFor="email"
              >
                E-mail
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-gray-100 dark:bg-gray-700 text-black dark:text-white cursor-pointer
                focus:outline-none focus:border-accentYellow dark:focus:border-accentYellow-dark"
                value={email}
                onClick={() => {
                  setStep(1);
                }}
                readOnly
              />
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                Klikněte na e-mail pro změnu
              </p>
            </div>

            {step === 3 && (
              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-300 mb-2"
                  htmlFor="name"
                >
                  Jméno
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="např. Jan Novák"
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-gray-100 dark:bg-gray-700 text-black dark:text-white
                  focus:outline-none focus:border-accentYellow dark:focus:border-accentYellow-dark"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
            )}

            <div className="mb-4 relative">
              <label
                className="block text-gray-700 dark:text-gray-300 mb-2"
                htmlFor="password"
              >
                Heslo
              </label>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                placeholder="Heslo123"
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-gray-100 dark:bg-gray-700 text-black dark:text-white
                focus:outline-none focus:border-accentYellow dark:focus:border-accentYellow-dark pr-10"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-3 flex items-center text-gray-500 dark:text-gray-300 transform translate-y-4"
                aria-label="Toggle password visibility"
              >
                {showPassword ? <FaEyeSlash size={22} /> : <FaEye size={22} />}
              </button>
            </div>

            {/* Přepínač pro "Pamatovat si mě" */}
            <div className="mb-6 flex items-center">
              <span className="text-gray-700 dark:text-gray-300 mr-3">
                Pamatovat si mě
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="sr-only"
                />
                <div
                  className={`w-12 h-6 rounded-full transition-colors duration-300 ease-in-out ${
                    rememberMe
                      ? 'bg-accentYellow dark:bg-accentYellow-dark'
                      : 'bg-gray-300 dark:bg-gray-600'
                  }`}
                >
                  <div
                    className={`absolute top-0.5 left-0.5 bg-white h-5 w-5 rounded-full transition-transform duration-300 ease-in-out ${
                      rememberMe ? 'translate-x-6' : ''
                    }`}
                  ></div>
                </div>
              </label>
            </div>

            <button
              type="submit"
              className="w-full bg-accentYellow text-gray-800 dark:bg-accentYellow-dark py-2 rounded transition duration-200
              hover:bg-accentYellow-hover dark:hover:bg-accentYellow-hover"
            >
              {step === 2 ? 'Přihlásit se' : 'Registrovat se'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default EmailForm;
