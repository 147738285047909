// src/CookiePolicy.js

import React from 'react';

function CookiePolicy() {
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 px-4 py-8">
      <div className="max-w-[800px] mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 mb-8 border-t-4 border-accentYellow">
        <h1 className="text-3xl font-bold text-black dark:text-white mb-6">
          Zásady používání cookies
        </h1>
        <div className="text-black dark:text-white">
          <p className="mb-4 text-sm text-gray-600 dark:text-gray-400">
            Naposledy aktualizováno 29. listopadu 2024
          </p>

          {/* Úvod */}
          <p className="mb-4">
            Tyto Zásady používání cookies vysvětlují, jak společnost SubwaySurveys
            ("Společnost", "my", "nás" a "naše") používá cookies a podobné technologie k
            rozpoznání vás, když navštívíte naše webové stránky na adrese{' '}
            <a href="https://subwaysurveys.eu" className="text-accentYellow">
              https://subwaysurveys.eu
            </a>{' '}
            ("Webové stránky"). Vysvětlují, co tyto technologie jsou a proč je používáme,
            stejně jako vaše práva kontrolovat naše používání těchto technologií.
          </p>

          <p className="mb-4">
            V některých případech můžeme používat cookies k shromažďování osobních údajů,
            nebo které se stanou osobními údaji, pokud je kombinujeme s jinými informacemi.
          </p>

          {/* Co jsou cookies? */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">Co jsou cookies?</h2>
          <p className="mb-4">
            Cookies jsou malé datové soubory, které jsou umístěny na vašem počítači nebo
            mobilním zařízení, když navštívíte webové stránky. Cookies jsou široce používány
            vlastníky webových stránek, aby jejich webové stránky fungovaly, nebo aby
            fungovaly efektivněji, stejně jako pro poskytování informací o provozu.
          </p>

          <p className="mb-4">
            Cookies nastavené vlastníkem webových stránek (v tomto případě SubwaySurveys) se
            nazývají "cookies první strany". Cookies nastavené stranami jinými než vlastníkem
            webových stránek se nazývají "cookies třetích stran". Cookies třetích stran
            umožňují poskytování funkcí nebo funkčností třetích stran na nebo prostřednictvím
            webových stránek (např. reklama, interaktivní obsah a analytika). Strany, které
            nastavují tyto cookies třetích stran, mohou rozpoznat váš počítač jak při návštěvě
            daných webových stránek, tak při návštěvě určitých dalších webových stránek.
          </p>

          {/* Proč používáme cookies? */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">Proč používáme cookies?</h2>
          <p className="mb-4">
            Používáme cookies první i třetí strany z několika důvodů. Některé cookies jsou
            vyžadovány z technických důvodů, aby naše Webové stránky fungovaly, a tyto
            označujeme jako "nezbytné" nebo "striktně nezbytné" cookies. Další cookies nám
            také umožňují sledovat a cílit zájmy našich uživatelů, abychom zlepšili
            uživatelskou zkušenost na našich online službách. Třetí strany používají
            prostřednictvím našich Webových stránek cookies pro reklamu, analytiku a další
            účely. To je podrobněji popsáno níže.
          </p>

          {/* Jak mohu kontrolovat cookies? */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">Jak mohu kontrolovat cookies?</h2>
          <p className="mb-4">
            Máte právo se rozhodnout, zda cookies přijmete nebo odmítnete. Svá práva týkající
            se cookies můžete uplatnit nastavením svých preferencí v nástroji Správce souhlasu
            s cookies. Správce souhlasu s cookies vám umožňuje vybrat, které kategorie cookies
            přijímáte nebo odmítáte. Nezbytné cookies nelze odmítnout, protože jsou striktně
            nezbytné k poskytování služeb.
          </p>

          <p className="mb-4">
            Správce souhlasu s cookies lze nalézt v oznamovacím banneru a na našich Webových
            stránkách. Pokud se rozhodnete cookies odmítnout, můžete naše Webové stránky
            stále používat, i když váš přístup k některým funkcím a oblastem našich Webových
            stránek může být omezen. Můžete také nastavit nebo upravit nastavení vašeho
            webového prohlížeče tak, aby přijímal nebo odmítal cookies.
          </p>

          <p className="mb-4">
            Specifické typy cookies první a třetí strany, které jsou prostřednictvím našich
            Webových stránek používány a účely, které plní, jsou popsány v tabulce níže
            (vezměte prosím na vědomí, že specifické cookies mohou variovat v závislosti na
            konkrétních službách, které navštívíte):
          </p>

          {/* Tabulka cookies */}
          <h3 className="text-xl font-semibold mt-6 mb-4">
            Nezbytné cookies webových stránek
          </h3>
          <p className="mb-4">
            Tyto cookies jsou striktně nezbytné k poskytování služeb dostupných prostřednictvím
            našich Webových stránek a k využití některých jejich funkcí, jako je přístup k
            zabezpečeným oblastem.
          </p>

          <table className="w-full mb-6 border-collapse">
            <thead>
              <tr>
                <th className="border p-2 text-left">Název</th>
                <th className="border p-2 text-left">Účel</th>
                <th className="border p-2 text-left">Poskytovatel</th>
                <th className="border p-2 text-left">Typ</th>
                <th className="border p-2 text-left">Vyprší za</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border p-2">__cf_bm</td>
                <td className="border p-2">
                  Cloudflare umisťuje cookie na zařízení koncových uživatelů, kteří přistupují
                  na stránky zákazníků chráněné nástroji Bot Management nebo Bot Fight Mode.
                </td>
                <td className="border p-2">.subwaysurveys.eu</td>
                <td className="border p-2">http_cookie</td>
                <td className="border p-2">29 minut</td>
              </tr>
            </tbody>
          </table>

          <h3 className="text-xl font-semibold mt-6 mb-4">Nezařazené cookies</h3>
          <p className="mb-4">
            Jedná se o cookies, které dosud nebyly kategorizovány. Pracujeme na klasifikaci
            těchto cookies s pomocí jejich poskytovatelů.
          </p>

          <table className="w-full mb-6 border-collapse">
            <thead>
              <tr>
                <th className="border p-2 text-left">Název</th>
                <th className="border p-2 text-left">Poskytovatel</th>
                <th className="border p-2 text-left">Typ</th>
                <th className="border p-2 text-left">Vyprší za</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border p-2">theme</td>
                <td className="border p-2">subwaysurveys.eu</td>
                <td className="border p-2">html_local_storage</td>
                <td className="border p-2">trvalé</td>
              </tr>
            </tbody>
          </table>

          {/* Jak mohu kontrolovat cookies ve svém prohlížeči? */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">
            Jak mohu kontrolovat cookies ve svém prohlížeči?
          </h2>
          <p className="mb-4">
            Způsoby, jak můžete odmítnout cookies prostřednictvím nastavení vašeho webového
            prohlížeče, se liší prohlížeč od prohlížeče. Pro více informací navštivte nabídku
            nápovědy vašeho prohlížeče. Níže naleznete informace o tom, jak spravovat cookies
            v nejpopulárnějších prohlížečích:
          </p>

          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>
              <a
                href="https://support.google.com/chrome/answer/95647?hl=cs"
                className="text-accentYellow"
              >
                Chrome
              </a>
            </li>
            <li>
              <a
                href="https://support.microsoft.com/cs-cz/help/17442/windows-internet-explorer-delete-manage-cookies"
                className="text-accentYellow"
              >
                Internet Explorer
              </a>
            </li>
            <li>
              <a
                href="https://support.mozilla.org/cs/kb/vymazani-cookies"
                className="text-accentYellow"
              >
                Firefox
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/cs-cz/guide/safari/sfri11471/mac"
                className="text-accentYellow"
              >
                Safari
              </a>
            </li>
            <li>
              <a
                href="https://support.microsoft.com/cs-cz/help/4027947/microsoft-edge-delete-cookies"
                className="text-accentYellow"
              >
                Edge
              </a>
            </li>
            <li>
              <a
                href="https://help.opera.com/cs/latest/web-preferences/#cookies"
                className="text-accentYellow"
              >
                Opera
              </a>
            </li>
          </ul>

          <p className="mb-4">
            Navíc většina reklamních sítí nabízí způsob, jak se odhlásit z cílené reklamy.
            Pokud byste chtěli získat více informací, navštivte prosím:
          </p>

          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>
              <a
                href="https://optout.aboutads.info/?c=2&lang=EN"
                className="text-accentYellow"
              >
                Digital Advertising Alliance
              </a>
            </li>
            <li>
              <a href="https://youradchoices.ca/" className="text-accentYellow">
                Digital Advertising Alliance of Canada
              </a>
            </li>
            <li>
              <a
                href="https://www.youronlinechoices.com/cz/"
                className="text-accentYellow"
              >
                European Interactive Digital Advertising Alliance
              </a>
            </li>
          </ul>

          {/* Co jiné sledovací technologie */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">
            Co jiné sledovací technologie, jako jsou webové majáky?
          </h2>
          <p className="mb-4">
            Cookies nejsou jediným způsobem, jak rozpoznat nebo sledovat návštěvníky webových
            stránek. Čas od času můžeme používat další podobné technologie, jako jsou webové
            majáky (někdy nazývané "sledovací pixely" nebo "čisté gify"). Jedná se o malé
            grafické soubory, které obsahují jedinečný identifikátor, který nám umožňuje
            rozpoznat, když někdo navštívil naše Webové stránky nebo otevřel e-mail, který je
            obsahuje. To nám například umožňuje sledovat pohyb uživatelů z jedné stránky
            webových stránek na druhou, doručovat nebo komunikovat s cookies, porozumět, zda
            jste na webové stránky přišli z online reklamy zobrazené na webových stránkách
            třetí strany, zlepšit výkon stránek a měřit úspěšnost e-mailových marketingových
            kampaní. V mnoha případech jsou tyto technologie závislé na cookies, aby správně
            fungovaly, a proto odmítnutí cookies ovlivní jejich fungování.
          </p>

          {/* Používáte Flash cookies */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">
            Používáte Flash cookies nebo Místně sdílené objekty?
          </h2>
          <p className="mb-4">
            Webové stránky mohou také používat takzvané "Flash cookies" (také známé jako
            Místně sdílené objekty nebo "LSO") k shromažďování a ukládání informací o vašem
            používání našich služeb, prevenci podvodů a pro další operace na stránkách.
          </p>

          <p className="mb-4">
            Pokud nechcete, aby byly Flash cookies ukládány na vašem počítači, můžete upravit
            nastavení vašeho Flash přehrávače tak, aby blokoval ukládání Flash cookies pomocí
            nástrojů obsažených v{' '}
            <a
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
              className="text-accentYellow"
            >
              Panelu nastavení úložiště webových stránek
            </a>
            . Můžete také ovládat Flash cookies přechodem na{' '}
            <a
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
              className="text-accentYellow"
            >
              Globální panel nastavení úložiště
            </a>{' '}
            a podle pokynů (které mohou zahrnovat pokyny, jak smazat existující Flash cookies,
            jak zabránit umístění Flash LSO na vašem počítači bez vašeho svolení a jak
            blokovat Flash cookies, které nejsou doručovány provozovatelem stránky, na které
            se právě nacházíte).
          </p>

          <p className="mb-4">
            Vezměte prosím na vědomí, že nastavení Flash Playeru pro omezení nebo omezení
            přijímání Flash cookies může snížit nebo narušit funkčnost některých Flash
            aplikací, včetně případně Flash aplikací používaných v souvislosti s našimi
            službami nebo online obsahem.
          </p>

          {/* Používáte cílenou reklamu? */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">Používáte cílenou reklamu?</h2>
          <p className="mb-4">
            Třetí strany mohou na vašem počítači nebo mobilním zařízení umístit cookies za
            účelem poskytování reklamy prostřednictvím našich Webových stránek. Tyto společnosti
            mohou používat informace o vašich návštěvách na těchto a dalších webových stránkách
            za účelem poskytování relevantních reklam o zboží a službách, které by vás mohly
            zajímat. Mohou také využívat technologie k měření účinnosti reklam. Mohou toho
            dosáhnout pomocí cookies nebo webových majáků k shromažďování informací o vašich
            návštěvách na těchto a jiných stránkách, aby vám poskytovali relevantní reklamy o
            zboží a službách, které by vás mohly zajímat. Informace shromážděné prostřednictvím
            tohoto procesu neumožňují nám ani jim identifikovat vaše jméno, kontaktní údaje
            nebo jiné detaily, které vás přímo identifikují, pokud se nerozhodnete je
            poskytnout.
          </p>

          {/* Jak často budete aktualizovat tyto Zásady používání cookies? */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">
            Jak často budete aktualizovat tyto Zásady používání cookies?
          </h2>
          <p className="mb-4">
            Tyto Zásady používání cookies můžeme čas od času aktualizovat, abychom reflektovali
            například změny cookies, které používáme, nebo z jiných provozních, právních nebo
            regulačních důvodů. Proto tyto Zásady používání cookies pravidelně znovu navštivte,
            abyste byli informováni o našem používání cookies a souvisejících technologií.
          </p>

          <p className="mb-4">
            Datum v horní části těchto Zásad používání cookies označuje, kdy byly naposledy
            aktualizovány.
          </p>

          {/* Kde mohu získat další informace? */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">
            Kde mohu získat další informace?
          </h2>
          <p className="mb-4">
            Pokud máte jakékoli dotazy ohledně našeho používání cookies nebo jiných
            technologií, napište nám na e-mail{' '}
            <a href="mailto:urbanekd.05@spst.eu" className="text-accentYellow">
              urbanekd.05@spst.eu
            </a>{' '}
            nebo poštou na adresu:
          </p>

          <p className="mb-4">
            <strong>SubwaySurveys</strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicy;
