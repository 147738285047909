import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Vytvoření AuthContext
export const AuthContext = createContext();

// AuthProvider komponenta
export const AuthProvider = ({ children }) => {
  // Inicializace tokenu přímo z úložiště
  const initialToken =
    localStorage.getItem('token') || sessionStorage.getItem('token') || '';
  const [token, setToken] = useState(initialToken);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Přidání stavu načítání

  // Nastavení Authorization headeru při inicializaci
  if (initialToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${initialToken}`;
    console.log('Authorization header nastaven při inicializaci:', initialToken);
  }

  // Funkce pro ukládání tokenu
  const storeToken = (newToken, remember) => {
    setToken(newToken);
    if (newToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      console.log('Token uložen a Authorization header nastaven:', newToken);

      if (remember) {
        localStorage.setItem('token', newToken);
        console.log('Token uložen v localStorage pro trvalé přihlášení.');
      } else {
        sessionStorage.setItem('token', newToken);
        console.log('Token uložen v sessionStorage pro dočasné přihlášení.');
      }
    } else {
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      console.log('Token odstraněn a Authorization header smazán.');
    }
  };

  // Načítání uživatelských dat při změně tokenu
  useEffect(() => {
    if (token) {
      console.log('Načítání uživatele s tokenem:', token);
      axios
        .get('/api/user')
        .then((response) => {
          console.log('Uživatelská data načtena:', response.data.user);
          setUser(response.data.user);
          setLoading(false); // Nastavení načítání na false
        })
        .catch((error) => {
          console.error('Chyba při načítání uživatele:', error);
          console.error('Odpověď serveru:', error.response);
          storeToken('');
          setUser(null);
          setLoading(false); // Nastavení načítání na false i při chybě
        });
    } else {
      setUser(null);
      setLoading(false); // Nastavení načítání na false, pokud není token
    }
  }, [token]);

  // Funkce pro přihlášení
  const login = async (email, password, rememberMe) => {
    try {
      console.log('Pokouším se přihlásit s e-mailem:', email);
      const response = await axios.post('/api/login', { email, password, rememberMe });
      console.log('Odpověď z přihlášení:', response.data);
      storeToken(response.data.token, rememberMe);
      setUser(response.data.user);
      return response.data.user;
    } catch (error) {
      console.error('Chyba při přihlášení:', error);
      throw error;
    }
  };

  // Funkce pro přihlášení přes token
  const loginWithToken = async (newToken) => {
    try {
      console.log('Pokouším se přihlásit s tokenem:', newToken);
      storeToken(newToken, true); // Uloží token a nastaví ho do `axios.defaults`
      const response = await axios.get('/api/user');
      console.log('Uživatelská data načtena přes token:', response.data.user);
      setUser(response.data.user);
      return response.data.user;
    } catch (error) {
      console.error('Chyba při přihlášení přes token:', error);
      storeToken(''); // Odstraníme token, pokud je neplatný
      setUser(null);
      throw error;
    }
  };

  // Funkce pro odhlášení
  const logout = () => {
    console.log('Odhlášení uživatele.');
    storeToken('');
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{ token, user, login, loginWithToken, logout, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};
