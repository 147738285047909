// client/src/Vote.js

import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { FaCopy, FaQrcode, FaTimes, FaArrowRight, FaChartBar, FaExclamation } from 'react-icons/fa';
import { QRCodeSVG } from 'qrcode.react';
import './index.css';

const isDarkMode = document.documentElement.classList.contains('dark');

function Vote() {
  const { slug } = useParams();
  const [survey, setSurvey] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [message, setMessage] = useState('');
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [browserId, setBrowserId] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);

  useEffect(() => {
    let storedBrowserId = localStorage.getItem('browserId');
    if (!storedBrowserId) {
      storedBrowserId = uuidv4();
      localStorage.setItem('browserId', storedBrowserId);
    }
    setBrowserId(storedBrowserId);

    const fetchSurvey = async () => {
      try {
        const response = await axios.get(`/api/surveys/${slug}/results`);
        console.log("Fetched Survey Data:", response.data); // Log to verify data structure
        const fetchedSurvey = response.data;
        setSurvey(fetchedSurvey);
        if (user && fetchedSurvey.userId === user.id) { // Opraveno z user.userId na user.id
          setIsOwner(true);
        }
      } catch (error) {
        console.error("Error fetching survey:", error);
        setMessage('Chyba při načítání ankety.');
      }
    };
    fetchSurvey();
  }, [slug, user]);

  const handleOptionChange = (optionId) => {
    if (survey.allowMultipleSelections) {
      if (selectedOptions.includes(optionId)) {
        setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
      } else {
        setSelectedOptions([...selectedOptions, optionId]);
      }
    } else {
      setSelectedOptions([optionId]);
    }
  };

  const handleVote = async (e) => {
    e.preventDefault();
    if (selectedOptions.length === 0) {
      setMessage('Vyberte alespoň jednu možnost.');
      return;
    }

    if (survey.allowMultipleSelections) {
      const voteLimit = survey.voteLimit;
      if (voteLimit && selectedOptions.length > voteLimit) {
        setMessage(`Můžete vybrat maximálně ${voteLimit} možností.`);
        return;
      }
    } else if (selectedOptions.length !== 1) {
      setMessage('Můžete vybrat pouze jednu možnost.');
      return;
    }

    try {
      await axios.post(
        `/api/surveys/${slug}/vote`,
        { options: selectedOptions },
        {
          headers: {
            'x-browser-id': browserId,
          },
        }
      );
      setMessage('');
      navigate(`/results/${slug}`);
    } catch (error) {
      console.error(error);
      setMessage(error.response?.data?.message || 'Chyba při odesílání hlasu.');
    }
  };

  const handleCopyLink = () => {
    const surveyUrl = `${window.location.origin}/vote/${survey.slug}`;
    navigator.clipboard.writeText(surveyUrl);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleToggleQRCode = () => {
    setShowQRCode(!showQRCode);
  };

  if (!survey) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900 text-black dark:text-white">
        Načítám anketu...
      </div>
    );
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}. ${year}, ${hours}:${minutes}`;
  };

  const surveyUrl = `${window.location.origin}/vote/${survey.slug}`;
  const voteLimit = survey.allowMultipleSelections
    ? survey.voteLimit || survey.options.length
    : 1;

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 px-4 py-8">
      <div className="max-w-[700px] mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 mb-8 border-t-4 border-accentYellow">
        <h1 className="text-3xl font-bold text-black dark:text-white">{survey.title}</h1>

        <p className="text-gray-600 dark:text-gray-300 mt-1 mb-6">
          Vytvořeno: <strong>{survey.createdAt ? formatDate(survey.createdAt) : "N/A"}</strong>
        </p>

        {survey.description && (
          <p className="mt-4 mb-6 text-black dark:text-white">{survey.description}</p>
        )}

        {/* Displaying styled error message if present */}
        {message && (
          <div className="flex items-center bg-red-200 dark:bg-red-700 text-red-700 dark:text-red-300 rounded-lg px-4 py-2 mb-4">
            <FaExclamation className="mr-2" />
            <span className="font-semibold">{message}</span>
          </div>
        )}

        <div className="mb-4 text-black dark:text-white">
          {survey.allowMultipleSelections ? (
            <>
              Můžete vybrat maximálně {voteLimit}{' '}
              {voteLimit === 1 ? 'možnost' : voteLimit <= 4 ? 'možnosti' : 'možností'}.
            </>
          ) : (
            'Vyberte si:'
          )}
        </div>

        <form onSubmit={handleVote}>
          <div className="mb-6">
            {survey.options && survey.options.length > 0 ? (
              survey.options.map((option) => (
                <div key={option.id} className="mb-2">
                  <label className="flex items-center">
                    <input
                      type={survey.allowMultipleSelections ? 'checkbox' : 'radio'}
                      name="option"
                      value={option.id}
                      checked={selectedOptions.includes(option.id)}
                      onChange={() => handleOptionChange(option.id)}
                      className="input-accentYellow"
                    />
                    <span className="ml-3 text-black dark:text-white">{option.text}</span>
                  </label>
                </div>
              ))
            ) : (
              <p className="text-red-500">Možnosti nejsou k dispozici.</p>
            )}
          </div>

          {/* Stacked buttons on mobile */}
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
            <button
              type="submit"
              className="flex items-center justify-center bg-accentYellow hover:bg-yellow-500 text-gray-800 font-semibold py-2 px-3 rounded transition duration-200 group w-full md:w-auto"
            >
              Odeslat hlas
              <FaArrowRight className="ml-2 transition-transform duration-300 transform group-hover:translate-x-1" />
            </button>

            <button
              type="button"
              onClick={() => navigate(`/results/${slug}`)}
              className="flex items-center justify-center bg-gray-500 text-white hover:bg-gray-600 font-semibold py-2 px-3 rounded transition duration-200 w-full md:w-auto"
            >
              <FaChartBar className="mr-2" />
              Zobrazit výsledky
            </button>
          </div>
        </form>
      </div>

      {isOwner && (
        <div className="max-w-[700px] mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 mt-8 relative border-t-4 border-accentYellow">
          <h2 className="text-2xl font-bold mb-4 text-black dark:text-white">Sdílejte tuto anketu</h2>
          {copied && (
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full bg-accentYellow text-black py-2 px-4 rounded opacity-100 animate-fade-out-up">
              Odkaz byl zkopírován
            </div>
          )}
          <div className="mb-4 text-black dark:text-white">Odkaz pro sdílení ankety:</div>
          <div className="relative">
            <input
              type="text"
              readOnly
              value={surveyUrl}
              className="border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-black dark:text-white p-3 w-full pr-24 rounded-lg focus:outline-none focus:ring-2 focus:ring-accentYellow"
            />
            <button
              onClick={handleCopyLink}
              className="absolute inset-y-0 right-12 flex items-center px-3 text-gray-500 dark:text-gray-300 hover:text-accentYellow dark:hover:text-accentYellow transition-colors duration-200 focus:outline-none group"
              aria-label="Kopírovat odkaz"
            >
              <FaCopy size={20} />
              <span className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-500 text-white text-xs rounded py-1 px-2">
                Kopírovat odkaz
              </span>
            </button>
            <button
              onClick={handleToggleQRCode}
              className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 dark:text-gray-300 hover:text-accentYellow dark:hover:text-accentYellow transition-colors duration-200 focus:outline-none group"
              aria-label="Zobrazit QR kód"
            >
              <FaQrcode size={20} />
              <span className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-500 text-white text-xs rounded py-1 px-2">
                Zobrazit QR kód
              </span>
            </button>
          </div>
          {showQRCode && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
              onClick={handleToggleQRCode}
            >
              <div
                className="bg-white dark:bg-gray-800 p-6 rounded-lg relative max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  onClick={handleToggleQRCode}
                  className="absolute top-2 right-2 text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-500 focus:outline-none"
                  aria-label="Zavřít"
                >
                  <FaTimes size={24} />
                </button>
                <h2 className="text-xl font-bold mb-4 text-black dark:text-white text-center">QR kód ankety</h2>
                <QRCodeSVG
                  value={surveyUrl}
                  size={300}
                  fgColor={isDarkMode ? '#FFFFFF' : '#000000'}
                  bgColor="transparent"
                  className="w-full h-auto"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Vote;
