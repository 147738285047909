// src/PrivacyPolicy.js

import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 px-4 py-8">
      <div className="max-w-[800px] mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 mb-8 border-t-4 border-accentYellow">
        <h1 className="text-3xl font-bold text-black dark:text-white mb-6">
          Zásady ochrany osobních údajů
        </h1>
        <div className="text-black dark:text-white">
          <p className="mb-4 text-sm text-gray-600 dark:text-gray-400">
            Naposledy aktualizováno 29. listopadu 2024
          </p>

          {/* Úvod */}
          <p className="mb-4">
            Toto Oznámení o ochraně osobních údajů pro SubwaySurveys ("my", "nás" nebo "naše")
            popisuje, jak a proč můžeme přistupovat, shromažďovat, ukládat, používat a/nebo
            sdílet ("zpracovávat") vaše osobní údaje, když používáte naše služby ("Služby"),
            včetně když:
          </p>

          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>
              Navštívíte naše webové stránky na adrese{' '}
              <a href="https://subwaysurveys.eu" className="text-accentYellow">
                https://subwaysurveys.eu
              </a>
              , nebo jakékoli naše webové stránky, které odkazují na toto Oznámení o ochraně
              osobních údajů
            </li>
            <li>
              Používáte SubwaySurveys. Poskytujeme platformu, která umožňuje uživatelům
              vytvářet, sdílet a spravovat ankety. Naše služba umožňuje uživatelům navrhovat
              ankety, sbírat zpětnou vazbu a analyzovat výsledky v reálném čase. Platforma je
              navržena tak, aby zajistila snadné použití, bezpečnost a přístupnost z jakéhokoli
              zařízení.
            </li>
            <li>
              Zapojíte se s námi jinými souvisejícími způsoby, včetně jakýchkoli prodejů,
              marketingu nebo událostí
            </li>
          </ul>

          <p className="mb-4">
            Otázky nebo obavy? Čtení tohoto Oznámení o ochraně osobních údajů vám pomůže
            porozumět vašim právům a možnostem souvisejícím s ochranou soukromí. Jsme odpovědní
            za rozhodování o tom, jak jsou vaše osobní údaje zpracovávány. Pokud nesouhlasíte s
            našimi zásadami a postupy, nepoužívejte prosím naše Služby. Pokud máte stále nějaké
            otázky nebo obavy, kontaktujte nás prosím na{' '}
            <a href="mailto:urbanekd.05@spst.eu" className="text-accentYellow">
              urbanekd.05@spst.eu
            </a>
            .
          </p>

          {/* SHRNUTÍ KLÍČOVÝCH BODŮ */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">SHRNUTÍ KLÍČOVÝCH BODŮ</h2>
          <p className="mb-4">
            Toto shrnutí poskytuje klíčové body z našeho Oznámení o ochraně osobních údajů, ale
            můžete zjistit více podrobností o kterémkoli z těchto témat kliknutím na odkaz
            následující po každém klíčovém bodu nebo pomocí našeho obsahu níže k nalezení
            sekce, kterou hledáte.
          </p>

          <p className="mb-4">
            <strong>Jaké osobní údaje zpracováváme?</strong> Když navštívíte, použijete nebo
            procházíte naše Služby, můžeme zpracovávat osobní údaje v závislosti na tom, jak s
            námi a Službami interagujete, jaké volby učiníte a jaké produkty a funkce
            používáte.{' '}
            <a href="#section1" className="text-accentYellow">
              Zjistěte více o osobních údajích, které nám sdělujete
            </a>
            .
          </p>

          <p className="mb-4">
            <strong>Zpracováváme nějaké citlivé osobní údaje?</strong> Některé informace mohou
            být v určitých jurisdikcích považovány za "zvláštní" nebo "citlivé", například vaše
            rasový nebo etnický původ, sexuální orientace a náboženské přesvědčení.{' '}
            <strong>Nezpracováváme citlivé osobní údaje.</strong>
          </p>

          <p className="mb-4">
            <strong>Shromažďujeme nějaké informace od třetích stran?</strong>{' '}
            <strong>Nezískáváme žádné informace od třetích stran.</strong>
          </p>

          <p className="mb-4">
            <strong>Jak zpracováváme vaše informace?</strong> Zpracováváme vaše informace za
            účelem poskytování, zlepšování a správy našich Služeb, komunikace s vámi, pro
            bezpečnost a prevenci podvodů a pro dodržování zákona. Můžeme také zpracovávat vaše
            informace pro jiné účely s vaším souhlasem. Zpracováváme vaše informace pouze tehdy,
            když k tomu máme platný právní důvod.{' '}
            <a href="#section2" className="text-accentYellow">
              Zjistěte více o tom, jak zpracováváme vaše informace
            </a>
            .
          </p>

          <p className="mb-4">
            <strong>
              V jakých situacích a s kterými stranami sdílíme osobní údaje?
            </strong>{' '}
            Můžeme sdílet informace ve specifických situacích a s konkrétními třetími stranami.{' '}
            <a href="#section4" className="text-accentYellow">
              Zjistěte více o tom, kdy a s kým sdílíme vaše osobní údaje
            </a>
            .
          </p>

          <p className="mb-4">
            <strong>Jak udržujeme vaše informace v bezpečí?</strong> Máme adekvátní organizační
            a technické procesy a postupy k ochraně vašich osobních údajů. Nicméně žádný
            elektronický přenos přes internet nebo technologie ukládání informací nemůže být
            zaručena jako 100% bezpečná, takže nemůžeme slíbit nebo zaručit, že hackeři,
            kyberzločinci nebo jiné neoprávněné třetí strany nebudou schopni překonat naši
            bezpečnost a nesprávně shromažďovat, přistupovat, krást nebo měnit vaše informace.{' '}
            <a href="#section8" className="text-accentYellow">
              Zjistěte více o tom, jak udržujeme vaše informace v bezpečí
            </a>
            .
          </p>

          <p className="mb-4">
            <strong>Jaká jsou vaše práva?</strong> V závislosti na tom, kde se geograficky
            nacházíte, může platný zákon o ochraně soukromí znamenat, že máte určitá práva
            týkající se vašich osobních údajů.{' '}
            <a href="#section10" className="text-accentYellow">
              Zjistěte více o vašich právech na ochranu soukromí
            </a>
            .
          </p>

          <p className="mb-4">
            <strong>Jak můžete uplatnit svá práva?</strong> Nejjednodušší způsob, jak uplatnit
            svá práva, je podat žádost o přístup k údajům nebo nás kontaktovat. Jakoukoli
            žádost zvážíme a vyřídíme v souladu s platnými zákony o ochraně údajů.
          </p>

          <p className="mb-4">
            <strong>
              Chcete se dozvědět více o tom, co děláme s jakýmikoli informacemi, které
              shromažďujeme?
            </strong>{' '}
            Přečtěte si celé Oznámení o ochraně osobních údajů.
          </p>

          {/* OBSAH */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">OBSAH</h2>
          <ol className="list-decimal list-inside mb-4 space-y-2">
            <li>
              <a href="#section1" className="text-accentYellow">
                Jaké informace shromažďujeme?
              </a>
            </li>
            <li>
              <a href="#section2" className="text-accentYellow">
                Jak zpracováváme vaše informace?
              </a>
            </li>
            <li>
              <a href="#section3" className="text-accentYellow">
                Na jakých právních základech se spoléháme při zpracování vašich osobních údajů?
              </a>
            </li>
            <li>
              <a href="#section4" className="text-accentYellow">
                Kdy a s kým sdílíme vaše osobní údaje?
              </a>
            </li>
            <li>
              <a href="#section5" className="text-accentYellow">
                Používáme cookies a jiné sledovací technologie?
              </a>
            </li>
            <li>
              <a href="#section6" className="text-accentYellow">
                Jak nakládáme s vašimi sociálními přihlášeními?
              </a>
            </li>
            <li>
              <a href="#section7" className="text-accentYellow">
                Jak dlouho uchováváme vaše informace?
              </a>
            </li>
            <li>
              <a href="#section8" className="text-accentYellow">
                Jak udržujeme vaše informace v bezpečí?
              </a>
            </li>
            <li>
              <a href="#section9" className="text-accentYellow">
                Shromažďujeme informace od nezletilých?
              </a>
            </li>
            <li>
              <a href="#section10" className="text-accentYellow">
                Jaká jsou vaše práva na ochranu soukromí?
              </a>
            </li>
            <li>
              <a href="#section11" className="text-accentYellow">
                Ovládací prvky pro funkce Nesledovat
              </a>
            </li>
            <li>
              <a href="#section12" className="text-accentYellow">
                Aktualizujeme toto oznámení?
              </a>
            </li>
            <li>
              <a href="#section13" className="text-accentYellow">
                Jak nás můžete kontaktovat ohledně tohoto oznámení?
              </a>
            </li>
            <li>
              <a href="#section14" className="text-accentYellow">
                Jak můžete přezkoumat, aktualizovat nebo smazat údaje, které od vás shromažďujeme?
              </a>
            </li>
          </ol>

          {/* 1. Jaké informace shromažďujeme? */}
          <h2 id="section1" className="text-2xl font-semibold mt-6 mb-4">
            1. Jaké informace shromažďujeme?
          </h2>
          <h3 className="text-xl font-semibold mt-4 mb-2">
            Osobní údaje, které nám sdělujete
          </h3>
          <p className="mb-4">
            <strong>Stručně:</strong> Shromažďujeme osobní údaje, které nám poskytnete.
          </p>
          <p className="mb-4">
            Shromažďujeme osobní údaje, které nám dobrovolně poskytnete, když se zaregistrujete
            na Službách, vyjádříte zájem o získání informací o nás nebo našich produktech a
            Službách, když se účastníte aktivit na Službách nebo jinak, když nás kontaktujete.
          </p>
          <h4 className="text-lg font-semibold mt-4 mb-2">Osobní údaje poskytnuté vámi</h4>
          <p className="mb-4">
            Osobní údaje, které shromažďujeme, závisí na kontextu vašich interakcí s námi a
            Službami, volbách, které učiníte, a produktech a funkcích, které používáte. Osobní
            údaje, které shromažďujeme, mohou zahrnovat následující:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>e-mailové adresy</li>
            <li>uživatelská jména</li>
            <li>hesla</li>
            <li>jména</li>
          </ul>
          <h4 className="text-lg font-semibold mt-4 mb-2">Citlivé informace</h4>
          <p className="mb-4">
            <strong>Nezpracováváme citlivé informace.</strong>
          </p>
          <h4 className="text-lg font-semibold mt-4 mb-2">Údaje o přihlášení přes sociální média</h4>
          <p className="mb-4">
            Můžeme vám poskytnout možnost se zaregistrovat u nás pomocí vašich stávajících údajů
            o účtu sociálních médií, jako je váš účet na Facebooku, X nebo jiném sociálním médiu.
            Pokud se rozhodnete zaregistrovat tímto způsobem, budeme shromažďovat určité
            informace o vašem profilu od poskytovatele sociálních médií, jak je popsáno v sekci{' '}
            <a href="#section6" className="text-accentYellow">
              "Jak nakládáme s vašimi sociálními přihlášeními?"
            </a>{' '}
            níže.
          </p>
          <p className="mb-4">
            Všechny osobní údaje, které nám poskytnete, musí být pravdivé, úplné a přesné a
            musíte nás informovat o jakýchkoli změnách takových osobních údajů.
          </p>

          <h4 className="text-lg font-semibold mt-4 mb-2">Google API</h4>
          <p className="mb-4">
            Naše použití informací získaných z Google API bude v souladu s{' '}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy"
              className="text-accentYellow"
              target="_blank"
              rel="noopener noreferrer"
            >
              Zásadami uživatelských dat služeb Google API
            </a>
            , včetně požadavků na omezené použití.
          </p>

          {/* 2. Jak zpracováváme vaše informace? */}
          <h2 id="section2" className="text-2xl font-semibold mt-6 mb-4">
            2. Jak zpracováváme vaše informace?
          </h2>
          <p className="mb-4">
            <strong>Stručně:</strong> Zpracováváme vaše informace za účelem poskytování,
            zlepšování a správy našich Služeb, komunikace s vámi, pro bezpečnost a prevenci
            podvodů a pro dodržování zákona. Můžeme také zpracovávat vaše informace pro jiné
            účely s vaším souhlasem.
          </p>
          <p className="mb-4">
            Zpracováváme vaše osobní údaje z různých důvodů, v závislosti na tom, jak
            interagujete s našimi Službami, včetně:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>
              <strong>Pro usnadnění vytvoření účtu a autentizace a jinak spravovat uživatelské účty.</strong>{' '}
              Můžeme zpracovávat vaše informace, abyste mohli vytvořit a přihlásit se k vašemu
              účtu, stejně jako udržovat váš účet v provozuschopném stavu.
            </li>
            <li>
              <strong>Pro záchranu nebo ochranu životně důležitého zájmu jednotlivce.</strong>{' '}
              Můžeme zpracovávat vaše informace, když je to nezbytné k záchraně nebo ochraně
              životně důležitého zájmu jednotlivce, například k prevenci újmy.
            </li>
          </ul>

          {/* 3. Na jakých právních základech se spoléháme při zpracování vašich osobních údajů? */}
          <h2 id="section3" className="text-2xl font-semibold mt-6 mb-4">
            3. Na jakých právních základech se spoléháme při zpracování vašich osobních údajů?
          </h2>
          <p className="mb-4">
            <strong>Stručně:</strong> Zpracováváme vaše osobní údaje pouze tehdy, když se
            domníváme, že je to nezbytné a máme platný právní důvod (tj. právní základ) k tomu
            podle platného zákona, jako je váš souhlas, pro dodržování zákonů, pro poskytování
            služeb, pro plnění našich smluvních povinností, pro ochranu vašich práv nebo pro
            naplnění našich oprávněných obchodních zájmů.
          </p>
          <p className="mb-4">
            Obecné nařízení o ochraně osobních údajů (GDPR) a UK GDPR vyžadují, abychom
            vysvětlili platné právní základy, na které se spoléháme při zpracování vašich
            osobních údajů. Proto se můžeme při zpracování vašich osobních údajů spoléhat na
            následující právní základy:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>
              <strong>Souhlas.</strong> Můžeme zpracovávat vaše informace, pokud jste nám dali
              svolení (tj. souhlas) používat vaše osobní údaje pro konkrétní účel. Svůj souhlas
              můžete kdykoli odvolat.{' '}
              <a href="#withdrawconsent" className="text-accentYellow">
                Zjistěte více o odvolání vašeho souhlasu
              </a>
              .
            </li>
            <li>
              <strong>Právní povinnosti.</strong> Můžeme zpracovávat vaše informace, když se
              domníváme, že je to nezbytné pro dodržení našich právních povinností, jako je
              spolupráce s orgánem činným v trestním řízení nebo regulační agenturou, uplatňování
              nebo obhajoba našich práv nebo zveřejnění vašich informací jako důkaz v soudním
              řízení, ve kterém jsme zapojeni.
            </li>
            <li>
              <strong>Životně důležité zájmy.</strong> Můžeme zpracovávat vaše informace, když se
              domníváme, že je to nezbytné k ochraně vašich životně důležitých zájmů nebo
              životně důležitých zájmů třetí strany, jako jsou situace zahrnující potenciální
              hrozby pro bezpečnost jakékoli osoby.
            </li>
          </ul>

          {/* 4. Kdy a s kým sdílíme vaše osobní údaje? */}
          <h2 id="section4" className="text-2xl font-semibold mt-6 mb-4">
            4. Kdy a s kým sdílíme vaše osobní údaje?
          </h2>
          <p className="mb-4">
            <strong>Stručně:</strong> Můžeme sdílet informace ve specifických situacích
            popsaných v této sekci a/nebo s následujícími třetími stranami.
          </p>
          <p className="mb-4">
            Můžeme potřebovat sdílet vaše osobní údaje v následujících situacích:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>
              <strong>Převody podnikání.</strong> Můžeme sdílet nebo převést vaše informace v
              souvislosti s jakýmkoli sloučením, prodejem firemních aktiv, financováním nebo
              akvizicí celého nebo části našeho podnikání jiné společnosti nebo během jednání o
              nich.
            </li>
          </ul>

          {/* 5. Používáme cookies a jiné sledovací technologie? */}
          <h2 id="section5" className="text-2xl font-semibold mt-6 mb-4">
            5. Používáme cookies a jiné sledovací technologie?
          </h2>
          <p className="mb-4">
            <strong>Stručně:</strong> Můžeme používat cookies a další sledovací technologie k
            shromažďování a ukládání vašich informací.
          </p>
          <p className="mb-4">
            Můžeme používat cookies a podobné sledovací technologie (jako jsou webové majáky a
            pixely) k získávání informací, když interagujete s našimi Službami. Některé online
            sledovací technologie nám pomáhají udržovat bezpečnost našich Služeb a vašeho účtu,
            předcházet pádům, opravovat chyby, ukládat vaše preference a pomáhat se základními
            funkcemi webu.
          </p>
          <p className="mb-4">
            Také umožňujeme třetím stranám a poskytovatelům služeb používat online sledovací
            technologie na našich Službách pro analytiku a reklamu, včetně pomoci při správě a
            zobrazování reklam, přizpůsobení reklam vašim zájmům nebo zasílání připomenutí o
            opuštěných nákupních košících (v závislosti na vašich preferencích komunikace).
            Třetí strany a poskytovatelé služeb používají svou technologii k poskytování reklamy
            o produktech a službách přizpůsobených vašim zájmům, které se mohou objevit buď na
            našich Službách nebo na jiných webových stránkách.
          </p>
          <p className="mb-4">
            Konkrétní informace o tom, jak používáme takové technologie a jak můžete odmítnout
            určité cookies, jsou uvedeny v našich{' '}
            <a href="/cookie-policy" className="text-accentYellow">
              Zásadách používání cookies
            </a>
            :{' '}
            <a href="https://subwaysurveys.eu/cookie-policy" className="text-accentYellow">
              https://subwaysurveys.eu/cookie-policy
            </a>
            .
          </p>

          {/* 6. Jak nakládáme s vašimi sociálními přihlášeními? */}
          <h2 id="section6" className="text-2xl font-semibold mt-6 mb-4">
            6. Jak nakládáme s vašimi sociálními přihlášeními?
          </h2>
          <p className="mb-4">
            <strong>Stručně:</strong> Pokud se rozhodnete zaregistrovat nebo přihlásit k našim
            Službám pomocí účtu na sociálních médiích, můžeme mít přístup k určitým informacím o
            vás.
          </p>
          <p className="mb-4">
            Naše Služby vám nabízejí možnost se zaregistrovat a přihlásit pomocí údajů vašeho
            účtu třetí strany na sociálních médiích (jako jsou vaše přihlášení na Facebook nebo
            X). Pokud se rozhodnete tak učinit, obdržíme určité informace o vašem profilu od
            poskytovatele sociálních médií. Informace o profilu, které obdržíme, se mohou lišit
            v závislosti na příslušném poskytovateli sociálních médií, ale často zahrnují vaše
            jméno, e-mailovou adresu, seznam přátel a profilový obrázek, stejně jako další
            informace, které se rozhodnete zveřejnit na takové platformě sociálních médií.
          </p>
          <p className="mb-4">
            Informace, které obdržíme, použijeme pouze pro účely popsané v tomto Oznámení o
            ochraně osobních údajů nebo které jsou vám jinak jasně sděleny na příslušných
            Službách. Vezměte prosím na vědomí, že nekontrolujeme a nejsme odpovědní za jiné
            použití vašich osobních údajů vaším poskytovatelem sociálních médií třetí strany.
            Doporučujeme, abyste si přečetli jejich oznámení o ochraně osobních údajů, abyste
            porozuměli, jak shromažďují, používají a sdílejí vaše osobní údaje a jak můžete
            nastavit své preference ochrany soukromí na jejich stránkách a aplikacích.
          </p>

          {/* 7. Jak dlouho uchováváme vaše informace? */}
          <h2 id="section7" className="text-2xl font-semibold mt-6 mb-4">
            7. Jak dlouho uchováváme vaše informace?
          </h2>
          <p className="mb-4">
            <strong>Stručně:</strong> Uchováváme vaše informace tak dlouho, jak je to nutné pro
            splnění účelů uvedených v tomto Oznámení o ochraně osobních údajů, pokud není
            zákonem požadováno jinak.
          </p>
          <p className="mb-4">
            Budeme uchovávat vaše osobní údaje pouze tak dlouho, jak je to nezbytné pro účely
            uvedené v tomto Oznámení o ochraně osobních údajů, pokud není vyžadována nebo
            povolena delší doba uchovávání zákonem (například daňové, účetní nebo jiné právní
            požadavky). Žádný účel v tomto oznámení nebude vyžadovat, abychom uchovávali vaše
            osobní údaje déle než dobu, po kterou mají uživatelé u nás účet.
          </p>
          <p className="mb-4">
            Když již nemáme žádnou legitimní obchodní potřebu zpracovávat vaše osobní údaje,
            buď je smažeme nebo anonymizujeme, nebo pokud to není možné (například proto, že
            vaše osobní údaje byly uloženy v záložních archivech), pak bezpečně uložíme vaše
            osobní údaje a izolujeme je od jakéhokoli dalšího zpracování, dokud nebude možné
            jejich smazání.
          </p>

          {/* 8. Jak udržujeme vaše informace v bezpečí? */}
          <h2 id="section8" className="text-2xl font-semibold mt-6 mb-4">
            8. Jak udržujeme vaše informace v bezpečí?
          </h2>
          <p className="mb-4">
            <strong>Stručně:</strong> Snažíme se chránit vaše osobní údaje prostřednictvím
            systému organizačních a technických bezpečnostních opatření.
          </p>
          <p className="mb-4">
            Implementovali jsme vhodná a přiměřená technická a organizační bezpečnostní opatření
            navržená k ochraně bezpečnosti jakýchkoli osobních údajů, které zpracováváme.
            Nicméně, navzdory našim zárukám a snahám zabezpečit vaše informace, žádný
            elektronický přenos přes internet nebo technologie ukládání informací nemůže být
            zaručena jako 100% bezpečná, takže nemůžeme slíbit nebo zaručit, že hackeři,
            kyberzločinci nebo jiné neoprávněné třetí strany nebudou schopni překonat naši
            bezpečnost a nesprávně shromažďovat, přistupovat, krást nebo měnit vaše informace.
            Ačkoli se budeme snažit chránit vaše osobní údaje, přenos osobních údajů do a z
            našich Služeb je na vaše vlastní riziko. Měli byste přistupovat ke Službám pouze v
            zabezpečeném prostředí.
          </p>

          {/* 9. Shromažďujeme informace od nezletilých? */}
          <h2 id="section9" className="text-2xl font-semibold mt-6 mb-4">
            9. Shromažďujeme informace od nezletilých?
          </h2>
          <p className="mb-4">
            <strong>Stručně:</strong> Vědomě neshromažďujeme data od dětí mladších 18 let ani
            na ně necílíme marketing.
          </p>
          <p className="mb-4">
            Vědomě neshromažďujeme, nevyžadujeme data od ani necílíme marketing na děti mladší
            18 let, ani vědomě neprodáváme takové osobní údaje. Používáním Služeb prohlašujete,
            že jste alespoň 18 let nebo že jste rodič nebo zákonný zástupce takového nezletilého
            a souhlasíte s používáním Služeb takovým nezletilým závislým. Pokud se dozvíme, že
            byly shromážděny osobní údaje od uživatelů mladších 18 let, deaktivujeme účet a
            přijmeme přiměřená opatření k okamžitému smazání takových dat z našich záznamů.
            Pokud se dozvíte o jakýchkoli datech, která jsme mohli shromáždit od dětí mladších
            18 let, kontaktujte nás prosím na{' '}
            <a href="mailto:urbanekd.05@spst.eu" className="text-accentYellow">
              urbanekd.05@spst.eu
            </a>
            .
          </p>

          {/* 10. Jaká jsou vaše práva na ochranu soukromí? */}
          <h2 id="section10" className="text-2xl font-semibold mt-6 mb-4">
            10. Jaká jsou vaše práva na ochranu soukromí?
          </h2>
          <p className="mb-4">
            <strong>Stručně:</strong> V některých regionech, jako je Evropský hospodářský prostor
            (EHP), Spojené království (UK) a Švýcarsko, máte práva, která vám umožňují větší
            přístup k vašim osobním údajům a kontrolu nad nimi. Můžete kdykoli přezkoumat,
            změnit nebo ukončit svůj účet, v závislosti na vaší zemi, provincii nebo státě
            bydliště.
          </p>
          <p className="mb-4">
            V některých regionech (jako je EHP, UK a Švýcarsko) máte určitá práva podle platných
            zákonů o ochraně údajů. Tato práva mohou zahrnovat právo (i) požadovat přístup a
            získat kopii vašich osobních údajů, (ii) požadovat opravu nebo vymazání; (iii)
            omezit zpracování vašich osobních údajů; (iv) pokud je to relevantní, na přenositelnost
            údajů; a (v) nebýt předmětem automatizovaného rozhodování. V určitých okolnostech
            můžete mít také právo vznést námitku proti zpracování vašich osobních údajů. Takovou
            žádost můžete učinit kontaktováním nás pomocí kontaktních údajů uvedených v sekci{' '}
            <a href="#section13" className="text-accentYellow">
              "Jak nás můžete kontaktovat ohledně tohoto oznámení?"
            </a>{' '}
            níže.
          </p>
          <p className="mb-4">
            Jakoukoli žádost zvážíme a vyřídíme v souladu s platnými zákony o ochraně údajů.
          </p>
          <p className="mb-4">
            Pokud se nacházíte v EHP nebo UK a věříte, že zpracováváme vaše osobní údaje
            nezákonně, máte také právo podat stížnost u svého dozorového úřadu pro ochranu údajů
            v členském státě nebo u úřadu pro ochranu údajů ve Spojeném království.
          </p>
          <p className="mb-4">
            Pokud se nacházíte ve Švýcarsku, můžete kontaktovat Federálního komisaře pro ochranu
            údajů a informace.
          </p>

          <h4 id="withdrawconsent" className="text-lg font-semibold mt-4 mb-2">
            Odvolání vašeho souhlasu
          </h4>
          <p className="mb-4">
            Pokud se spoléháme na váš souhlas se zpracováním vašich osobních údajů, máte právo
            svůj souhlas kdykoli odvolat. Svůj souhlas můžete kdykoli odvolat kontaktováním nás
            pomocí kontaktních údajů uvedených v sekci{' '}
            <a href="#section13" className="text-accentYellow">
              "Jak nás můžete kontaktovat ohledně tohoto oznámení?"
            </a>{' '}
            níže.
          </p>
          <p className="mb-4">
            Vezměte prosím na vědomí, že to neovlivní zákonnost zpracování před jeho odvoláním
            ani to neovlivní zpracování vašich osobních údajů prováděné v souladu s právními
            základy zpracování jinými než souhlasem.
          </p>

          <h4 className="text-lg font-semibold mt-4 mb-2">Informace o účtu</h4>
          <p className="mb-4">
            Pokud byste kdykoli chtěli přezkoumat nebo změnit informace ve svém účtu nebo
            ukončit svůj účet, můžete:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Kontaktovat nás pomocí poskytnutých kontaktních údajů.</li>
          </ul>
          <p className="mb-4">
            Na vaši žádost o ukončení vašeho účtu deaktivujeme nebo smažeme váš účet a informace
            z našich aktivních databází. Nicméně můžeme uchovávat některé informace v našich
            záznamech, abychom zabránili podvodům, řešili problémy, pomáhali s jakýmikoli
            vyšetřováními, vymáhali naše právní podmínky a/nebo dodržovali platné právní
            požadavky.
          </p>
          <h4 className="text-lg font-semibold mt-4 mb-2">Cookies a podobné technologie</h4>
          <p className="mb-4">
            Většina webových prohlížečů je nastavena tak, aby přijímala cookies ve výchozím
            nastavení. Pokud si přejete, můžete se obvykle rozhodnout nastavit svůj prohlížeč tak,
            aby odstraňoval cookies a odmítal cookies. Pokud se rozhodnete odstranit cookies nebo
            odmítnout cookies, může to ovlivnit určité funkce nebo služby našich Služeb. Pro
            další informace si prosím přečtěte naše{' '}
            <a href="/cookie-policy" className="text-accentYellow">
              Zásady používání cookies
            </a>
            :{' '}
            <a href="https://subwaysurveys.eu/cookie-policy" className="text-accentYellow">
              https://subwaysurveys.eu/cookie-policy
            </a>
            .
          </p>
          <p className="mb-4">
            Pokud máte otázky nebo připomínky k vašim právům na ochranu soukromí, můžete nám
            napsat na{' '}
            <a href="mailto:urbanekd.05@spst.eu" className="text-accentYellow">
              urbanekd.05@spst.eu
            </a>
            .
          </p>

          {/* 11. Ovládací prvky pro funkce Nesledovat */}
          <h2 id="section11" className="text-2xl font-semibold mt-6 mb-4">
            11. Ovládací prvky pro funkce Nesledovat
          </h2>
          <p className="mb-4">
            Většina webových prohlížečů a některé mobilní operační systémy a mobilní aplikace
            zahrnují funkci Nesledovat ("DNT") nebo nastavení, které můžete aktivovat k
            signalizaci vašeho přání na ochranu soukromí, aby nebyla data o vašich online
            aktivitách procházení monitorována a shromažďována. V této fázi nebyl dokončen
            jednotný technologický standard pro rozpoznávání a implementaci signálů DNT. Proto
            v současné době nereagujeme na signály prohlížeče DNT ani na žádný jiný mechanismus,
            který automaticky sděluje vaši volbu nebýt online sledováni. Pokud bude v budoucnu
            přijat standard pro online sledování, který budeme muset dodržovat, budeme vás o
            této praxi informovat v revidované verzi tohoto Oznámení o ochraně osobních údajů.
          </p>

          {/* 12. Aktualizujeme toto oznámení? */}
          <h2 id="section12" className="text-2xl font-semibold mt-6 mb-4">
            12. Aktualizujeme toto oznámení?
          </h2>
          <p className="mb-4">
            <strong>Stručně:</strong> Ano, toto oznámení budeme podle potřeby aktualizovat, aby
            bylo v souladu s příslušnými zákony.
          </p>
          <p className="mb-4">
            Toto Oznámení o ochraně osobních údajů můžeme čas od času aktualizovat. Aktualizovaná
            verze bude označena aktualizovaným datem "Revidováno" v horní části tohoto Oznámení
            o ochraně osobních údajů. Pokud provedeme podstatné změny tohoto Oznámení o ochraně
            osobních údajů, můžeme vás upozornit buď výrazným zveřejněním oznámení o takových
            změnách, nebo vám přímo zašleme oznámení. Doporučujeme vám pravidelně přezkoumávat
            toto Oznámení o ochraně osobních údajů, abyste byli informováni o tom, jak chráníme
            vaše informace.
          </p>

          {/* 13. Jak nás můžete kontaktovat ohledně tohoto oznámení? */}
          <h2 id="section13" className="text-2xl font-semibold mt-6 mb-4">
            13. Jak nás můžete kontaktovat ohledně tohoto oznámení?
          </h2>
          <p className="mb-4">
            Pokud máte otázky nebo připomínky k tomuto oznámení, můžete nám napsat na{' '}
            <a href="mailto:urbanekd.05@spst.eu" className="text-accentYellow">
              urbanekd.05@spst.eu
            </a>{' '}
            nebo nás kontaktovat poštou na adrese:
          </p>
          <p className="mb-4">
            <strong>SubwaySurveys</strong>
          </p>

          {/* 14. Jak můžete přezkoumat, aktualizovat nebo smazat údaje, které od vás shromažďujeme? */}
          <h2 id="section14" className="text-2xl font-semibold mt-6 mb-4">
            14. Jak můžete přezkoumat, aktualizovat nebo smazat údaje, které od vás shromažďujeme?
          </h2>
          <p className="mb-4">
            Na základě platných zákonů vaší země můžete mít právo požádat o přístup k osobním
            údajům, které od vás shromažďujeme, podrobnosti o tom, jak jsme je zpracovali,
            opravit nepřesnosti nebo smazat vaše osobní údaje. Můžete mít také právo odvolat
            svůj souhlas s naším zpracováním vašich osobních údajů. Tato práva mohou být v
            některých případech omezena platným zákonem. Chcete-li požádat o přezkoumání,
            aktualizaci nebo smazání vašich osobních údajů, vyplňte a odešlete žádost o přístup
            subjektu údajů.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
