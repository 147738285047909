// client/src/ProtectedRoute.js

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

function ProtectedRoute({ children }) {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    // Zobrazíme indikátor načítání nebo prázdný fragment
    return null; // Nebo můžete vrátit <LoadingSpinner /> pokud máte komponentu pro načítání
  }

  if (!user) {
    // Uživatel není přihlášen
    return <Navigate to="/auth" replace />;
  }

  // Uživatel je přihlášen, zobrazíme požadovanou komponentu
  return children;
}

export default ProtectedRoute;
