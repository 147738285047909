// src/SurveyList.js

import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { FaTrash, FaCheck, FaBan, FaPlus, FaExclamation, FaTimes } from 'react-icons/fa';
import { PiEyeBold, PiEyeClosedBold } from 'react-icons/pi';
import { v4 as uuidv4 } from 'uuid';

function SurveyList() {
  const { token } = useContext(AuthContext);
  const [surveys, setSurveys] = useState([]);
  const [hoveredSurveyId, setHoveredSurveyId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [browserId] = useState(localStorage.getItem('browserId') || uuidv4());
  const [statusMessage, setStatusMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('browserId')) {
      localStorage.setItem('browserId', browserId);
    }

    const fetchSurveys = async () => {
      try {
        const response = await axios.get('/api/surveys', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (Array.isArray(response.data)) {
          setSurveys(response.data);
        } else {
          console.error('Unexpected API response format:', response.data);
          setSurveys([]);
        }
      } catch (error) {
        console.error('Error fetching surveys:', error);
        setSurveys([]);
      }
    };

    fetchSurveys();
  }, [token, browserId]);

  // Funkce pro smazání ankety
  const deleteSurvey = async (id) => {
    try {
      await axios.delete(`/api/surveys/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSurveys((prevSurveys) => prevSurveys.filter((survey) => survey.id !== id));
    } catch (error) {
      console.error(error);
    }
    setShowModal(false);
  };

  // Potvrzení smazání
  const handleDeleteConfirm = (id) => {
    setSurveyToDelete(id);
    setShowModal(true);
  };

  // Zavření modalu
  const closeModal = () => {
    setShowModal(false);
    setSurveyToDelete(null);
  };

  // Kliknutí mimo modal
  const handleOutsideClick = (event) => {
    if (event.target.id === 'modal-overlay') {
      closeModal();
    }
  };

  // Přepnutí hlasování
  const toggleVoting = async (id) => {
    try {
      const response = await axios.patch(
        `/api/surveys/${id}/toggle-voting`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedSurvey = response.data.survey;
      const newStatus = updatedSurvey.votingEnabled
        ? 'Hlasování bylo povoleno.'
        : 'Hlasování bylo zakázáno.';

      setSurveys((prevSurveys) =>
        prevSurveys.map((survey) =>
          survey.id === id ? { ...survey, votingEnabled: updatedSurvey.votingEnabled } : survey
        )
      );

      // Nastavení zprávy
      setStatusMessage(newStatus);

      // Vymazání zprávy po 3 sekundách
      setTimeout(() => {
        setStatusMessage('');
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  // Formátování data
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}. ${year}, ${hours}:${minutes}`;
  };

  // Komponenta pro nadpis ankety s tooltipem
  const SurveyTitle = ({ title, id }) => {
    const titleRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
      const element = titleRef.current;
      if (element) {
        setIsTruncated(
          element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
        );
      }
    }, [title]);

    return (
      <div className="relative md:w-55%]">
        <h2
          ref={titleRef}
          className="text-2xl font-semibold mb-1 text-black dark:text-white break-words overflow-hidden text-ellipsis"
          style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
          onMouseEnter={() => isTruncated && setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {title}
        </h2>
        {/* Tooltip s celým názvem, zobrazený pouze pokud je text zkrácený */}
        {showTooltip && (
          <div className="absolute top-full left-0 mt-1 z-10 bg-white dark:bg-gray-700 text-black dark:text-white p-2 rounded shadow-lg w-64">
            {title}
          </div>
        )}
      </div>
    );
  };

  // Komponenta modalu
  const DeleteConfirmationModal = () => (
    <div
      id="modal-overlay"
      onClick={handleOutsideClick}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div className="relative bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-[500px]">
        <button
          onClick={closeModal}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 text-xl"
        >
          <FaTimes />
        </button>

        <h2 className="text-lg font-bold text-black dark:text-white mb-4">Odstranit anketu</h2>
        <p className="text-sm text-gray-700 dark:text-gray-300 mb-6">
          Opravdu chcete odstranit tuto anketu? Všechna související data, včetně hlasů, budou
          odstraněna. Tato akce nelze vrátit zpět.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={closeModal}
            className="bg-gray-400 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded"
          >
            Zrušit
          </button>
          <button
            onClick={() => deleteSurvey(surveyToDelete)}
            className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
          >
            Odstranit
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 px-4 py-8">
      <div className="max-w-[900px] mx-auto">
        <div className="mb-6 flex flex-col md:flex-row md:items-center md:justify-between">
          <h1 className="text-3xl font-bold mb-4 md:mb-0 text-black dark:text-white">Moje Ankety</h1>
          <div className="flex items-center space-x-4">
            {/* Zpráva o stavu */}
            {statusMessage && (
              <div
                className={`flex items-center py-2 px-3 rounded ${
                  statusMessage.includes('povoleno')
                    ? 'bg-green-200 dark:bg-green-700 text-green-700 dark:text-green-300'
                    : 'bg-red-200 dark:bg-red-700 text-red-700 dark:text-red-300'
                }`}
              >
                {statusMessage.includes('povoleno') ? (
                  <FaCheck className="mr-2" />
                ) : (
                  <FaExclamation className="mr-2" />
                )}
                <span className="font-semibold">{statusMessage}</span>
              </div>
            )}
            <button
              onClick={() => navigate('/surveys')}
              className="flex items-center bg-accentYellow hover:bg-yellow-500 text-gray-800 font-semibold py-2 px-3 rounded transition duration-200"
            >
              <FaPlus className="mr-2" />
              Přidat Anketu
            </button>
          </div>
        </div>

        {surveys.length === 0 ? (
          <p className="text-black dark:text-white">Nemáte žádné ankety.</p>
        ) : (
          <div className="space-y-4">
            {surveys.map((survey) => {
              const totalVotes = survey.options.reduce((acc, option) => acc + option.votes, 0);

              return (
                <div
                  key={survey.id}
                  className="flex flex-col md:flex-row md:items-center justify-between p-4 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 shadow"
                >
                  <div className="flex flex-col mb-4 md:mb-0 md:items-start md:flex-1">
                    <SurveyTitle title={survey.title} id={survey.id} />
                    <p className="text-gray-600 dark:text-gray-300">
                      Vytvořeno: <strong>{formatDate(survey.createdAt)}</strong>
                    </p>
                    <p className="text-gray-600 dark:text-gray-300">
                      Počet hlasujících: <strong>{totalVotes}</strong>
                    </p>
                  </div>

                  <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4">
                    <button
                      onClick={() => toggleVoting(survey.id)}
                      className={`py-2 px-4 rounded font-semibold flex items-center ${
                        survey.votingEnabled
                          ? 'bg-green-200 hover:bg-green-300 text-green-700 dark:bg-green-700 dark:hover:bg-green-600 dark:text-green-300'
                          : 'bg-red-200 hover:bg-red-300 text-red-700 dark:bg-red-700 dark:hover:bg-red-600 dark:text-red-300'
                      } transition duration-200`}
                      title="Přepnout stav ankety"
                    >
                      {survey.votingEnabled ? (
                        <FaCheck className="mr-2" />
                      ) : (
                        <FaBan className="mr-2" />
                      )}
                      {survey.votingEnabled ? 'Probíhá' : 'Uzavřeno'}
                    </button>

                    <Link
                      to={`/results/${survey.slug}`}
                      className="flex items-center bg-accentYellow hover:bg-yellow-500 text-gray-800 py-2 px-3 rounded font-semibold transition duration-200"
                      onMouseEnter={() => setHoveredSurveyId(survey.id)}
                      onMouseLeave={() => setHoveredSurveyId(null)}
                    >
                      {hoveredSurveyId === survey.id ? (
                        <PiEyeClosedBold className="mr-2" />
                      ) : (
                        <PiEyeBold className="mr-2" />
                      )}
                      Zobrazit výsledky
                    </Link>

                    <button
                      onClick={() => handleDeleteConfirm(survey.id)}
                      className="text-red-500 hover:text-red-700 dark:hover:text-red-500 transition-transform duration-300 transform hover:rotate-12"
                      title="Odstranit anketu"
                    >
                      <FaTrash size={32} />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {showModal && <DeleteConfirmationModal />}
    </div>
  );
}

export default SurveyList;
